import { Col, Typography } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLElement> {
  title: string;
  metreage: number | undefined;
  metreageClassName?: string;
  loading: boolean;
}

export default function BalanceMetreage({
  className,
  title,
  metreage,
  metreageClassName,
  loading,
  ...rest
}: Props) {
  return (
    <Col
      {...rest}
      className={
        className +
        ' rounded border border-gray-300 border-solid p-1 flex flex-col items-center'
      }
    >
      <Typography.Text type="secondary" className="text-center">
        {title}
      </Typography.Text>
      {loading ? (
        <SkeletonButton size="small" />
      ) : (
        metreage && (
          <Typography.Text strong className={metreageClassName}>
            {metreage.toFixed(2)}m²
          </Typography.Text>
        )
      )}
    </Col>
  );
}
