import React, { useContext, useState } from 'react';

type PaginationType = {
  [key: string]: number;
};

type ContextType = {
  pagination: PaginationType;
  handleUpdatePagination: (value: PaginationType) => void;
};

export const PaginationContext = React.createContext({} as ContextType);

export const PaginationContextProvider: React.FC = ({ children }) => {
  const [pagination, setPagination] = useState({} as PaginationType);

  const handleUpdatePagination = (value: PaginationType) =>
    setPagination(pages => ({ ...pages, ...value }));

  const value = { pagination, handleUpdatePagination };

  return <PaginationContext.Provider {...{ value, children }} />;
};

export function usePagination() {
  const context = useContext(PaginationContext);
  return context;
}
