import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Typography } from 'antd';

import { AppState } from '../../common/redux';
import { ServiceOrderTypes } from '../../common/redux/states/serviceOrder/types';

import RequestSteps from './RequestSteps';
import { useSearchParams } from 'react-router-dom';

export default function ServiceOrder() {
  const dispatch = useDispatch();

  const os = useSelector((state: AppState) => {
    return state.OrderService;
  });

  useEffect(() => {
    dispatch({
      type: ServiceOrderTypes.CLEAR_OS,
    });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ServiceOrderTypes.CLEAR_OS,
      });
    };
  }, [dispatch]);

  const [searchParams] = useSearchParams();
  const query = searchParams.get('finality_inspection') ?? undefined;

  const finalityInspection: Record<string, string | undefined> = {
    entry: 'de entrada',
    exit: 'de saída',
    comparison: 'de comparação',
  };
  function getFinalityInspection() {
    return query ? finalityInspection[query] : '';
  }

  return (
    <section className="p-4">
      <Row className="items-baseline">
        <Typography.Title level={2}>
          Solicitar vistoria {getFinalityInspection()}
        </Typography.Title>
        <Typography.Text type="secondary" className="ml-3">
          Agendamento de nova vistoria
        </Typography.Text>
      </Row>

      <RequestSteps {...{ os }} />
    </section>
  );
}
