import { Typography, Collapse, Button, notification } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import PersonForm from "./PersonForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderService from "../../common/interfaces/OrderService";
import { axiosInstanceSync } from "../../services/axios";

const { Title } = Typography;

const GET_SIGNERS = gql`
  query getSigners($id: String!) {
    getOrderServiceClient(id: $id) {
      tenants {
        id
        name
        last_name
        profession
        cpf
        cnpj
        status_civil
        nationality
        ci
        by_proxy
        by_proxy_cpf
      }
      locators {
        id
        name
        last_name
        profession
        cpf
        cnpj
        status_civil
        nationality
        ci
        by_proxy
        by_proxy_cpf
      }
      guarantors {
        id
        name
        last_name
        profession
        cpf
        cnpj
        status_civil
        nationality
        ci
        by_proxy
        by_proxy_cpf
      }
      witness {
        id
        name
        last_name
        profession
        cpf
        cnpj
        status_civil
        nationality
        ci
        by_proxy
        by_proxy_cpf
      }
    }
  }
`;

export default function OSEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const [queryOrderService, { data, loading }] = useLazyQuery<{
    getOrderServiceClient: OrderService;
  }>(GET_SIGNERS, {
    fetchPolicy: "no-cache",
    context: { uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL },
  });

  const signersData = data?.getOrderServiceClient;

  const [formsData, setFormsData] = useState({});
  const location = useLocation();

  const handleSaveForm = (data: { [key: string]: [] }) => {
    setFormsData((prev) => ({ ...prev, ...data }));
  };

  useLayoutEffect(() => {
    queryOrderService({ variables: { id: params?.id } }).then(({ data }) => {
      const signersData = data?.getOrderServiceClient;

      if (signersData) {
        const initial = {
          guarantors: signersData.guarantors || [],
          tenants: signersData.tenants || [],
          locators: signersData.locators || [],
          witness: signersData.witness || [],
        };
        setFormsData(initial);
      }
    });
  }, [location, params?.id, queryOrderService]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleConclude = () => {
    setIsSubmitting(true);
    axiosInstanceSync
      .put(
        `/client/order-service/${params?.id}/update`,
        { data: formsData },
        { baseURL: process.env.REACT_APP_API_VISTORIA + "/api" }
      )
      .then(() => {
        navigate(-1);
        notification.success({
          message: "Dados atualizados com sucesso!",
          duration: 1,
        });
      })
      .catch((e) => {
        notification.error({
          message: e.response?.data?.error ?? e.message ?? String(e),
          duration: 1,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <section style={{ padding: "1rem" }}>
      <div className="flex justify-between">
        <Title level={2}>Editar Locador/Locatário/Fiador</Title>
        <Button onClick={handleConclude} type="primary" loading={isSubmitting}>
          Salvar e voltar
        </Button>
      </div>

      {loading && (
        <Title level={5} style={{ textAlign: "center" }}>
          Carregando...
        </Title>
      )}

      {!!signersData && (
        <>
          <Collapse defaultActiveKey={[1, 2, 3]} bordered={false}>
            {signersForms.map(({ header, panelKey, formKey }) => (
              <Collapse.Panel header={header} key={panelKey}>
                <PersonForm
                  keyName={formKey}
                  initialValues={signersData[formKey]}
                  handleSaveForm={handleSaveForm}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </>
      )}
    </section>
  );
}

const signersForms: SignerForm[] = [
  { header: "Fiador(es)", panelKey: 1, formKey: "guarantors" },
  { header: "Locador(es)", panelKey: 2, formKey: "locators" },
  { header: "Locatário(s)", panelKey: 3, formKey: "tenants" },
  { header: "Testemunha(s)", panelKey: 3, formKey: "witness" },
];

interface SignerForm {
  header: string;
  panelKey: number;
  formKey: "guarantors" | "tenants" | "locators" | "witness";
}
