import { Moment } from 'moment';
import { SCHEDULE_TIME_COMBINATIONS } from './constants/schedule';
import { range } from './utils/range';

export function disabledScheduleTime(momentDate: Moment | null) {
  const minutesRange = [...range(0, 59)];

  const hour = momentDate?.hour();
  const currentCombination = SCHEDULE_TIME_COMBINATIONS.find(
    c => c.hour === hour
  );

  if (currentCombination) {
    minutesRange.splice(minutesRange.indexOf(currentCombination.minute), 1);
  }

  return {
    disabledHours: () => [...range(0, 7), 9, 11, 12, 14, ...range(16, 24)],
    disabledMinutes: () => minutesRange,
  };
}
