import { OrderServiceState } from './states/serviceOrder/types';
import { createStore } from 'redux';
import rootReducer from './states/rootReducer';

export interface AppState {
  OrderService: OrderServiceState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
