import Routes from './router';
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';

import client from './services/apollo';
import { AuthContextProvider } from './contexts/auth';
import { StatementContextProvider } from './contexts/statement';
import { PaginationContextProvider } from './contexts/pagination';

function App() {
  return (
    <ConfigProvider
      locale={ptBR}
      getPopupContainer={trigger => trigger ?? document.body}
    >
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <PaginationContextProvider>
            <StatementContextProvider>
              <Routes />
            </StatementContextProvider>
          </PaginationContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
}

export default App;
