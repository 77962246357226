import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import * as Dashboard from '../views/Dashboard';
import AppLayout from '../views/AppLayout';
import Login from '../views/Login';
import ServiceOrder from '../views/OrderService';
import * as OSList from '../views/OSList';
import OSOverview from '../views/OSOverview';
import OSEdit from '../views/OSEdit';
import Suggestions from '../views/Suggestions';
import Information from '../views/User';
import Statement from '../views/Statement';
import UserEdit from '../views/UserEdit';
import { withAuth } from './with-auth';

const router = createBrowserRouter([
  { path: '/login', element: <Login /> },
  {
    element: <AppLayout />,
    loader: withAuth(),
    children: [
      {
        path: '/new-inspection',
        loader: withAuth(),
        element: <ServiceOrder />,
      },
      {
        path: '/my-requests/:id/edit',
        loader: withAuth(),
        element: <OSEdit />,
      },
      { path: '/my-requests/:id', loader: withAuth(), element: <OSOverview /> },
      {
        path: '/my-requests',
        loader: withAuth(OSList.loader),
        element: <OSList.Component />,
      },
      { path: '/statement', loader: withAuth(), element: <Statement /> },
      { path: '/user', loader: withAuth(), element: <Information /> },
      { path: '/user/edit', loader: withAuth(), element: <UserEdit /> },
      { path: '/information', loader: () => redirect('/user') },
      { path: '/suggestions', loader: withAuth(), element: <Suggestions /> },
      {
        path: '/dashboard',
        loader: withAuth(Dashboard.loader),
        element: <Dashboard.Component />,
      },
      { index: true, loader: () => redirect('/dashboard') },
    ],
  },
]);

export default function Routes() {
  return <RouterProvider router={router} />;
}
