import { useQuery, gql } from '@apollo/client';
import { Button, notification, Skeleton } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { HTMLAttributes, useState } from 'react';
import { removeOrderServiceAttachment } from '../../services/http/order-service';

interface Props extends HTMLAttributes<HTMLElement> {
  orderServiceId: string;
}

const GET_ATTACHMENTS = gql`
  query getAttachments($orderServiceId: String!) {
    getAttachmentOrderServiceClient(orderServiceId: $orderServiceId) {
      id
      order
      path
      name
    }
  }
`;

type GetAttachments = {
  getAttachmentOrderServiceClient: AttachmentOrderService[];
};

type AttachmentOrderService = {
  id: string;
  order: number;
  path: string;
  name: string;
};

export default function Attachments({
  orderServiceId,
  ...containerProps
}: Props) {
  const { data, loading, refetch } = useQuery<GetAttachments>(GET_ATTACHMENTS, {
    variables: { orderServiceId },
  });
  const attachments = data?.getAttachmentOrderServiceClient;

  const [loadingRemoveAttach, setLoadingRemoveAttach] = useState(false);

  const handleRemoveAttachClick = (attachmentId: string) => {
    return () => {
      setLoadingRemoveAttach(true);
      removeOrderServiceAttachment(attachmentId)
        .then(() => {
          refetch();
          notification.success({ message: 'Removido!' });
        })
        .catch(error => {
          notification.error({
            message: 'Erro inesperado ao remover!',
            description: error?.toString?.(),
          });
        })
        .finally(() => setLoadingRemoveAttach(false));
    };
  };

  return (
    <Skeleton {...{ loading }}>
      <section {...containerProps}>
        <Title level={4}>Anexos</Title>
        {!attachments || !attachments.length ? (
          <Text strong>Nada foi anexado.</Text>
        ) : (
          attachments.map(({ id, path, name }) => (
            <div key={id} className="flex items-center gap-2">
              <PaperClipOutlined />
              <a
                href={`${process.env.REACT_APP_DEPENDENCY_PHOTO}${path}`}
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>

              <Button
                loading={loadingRemoveAttach}
                onClick={handleRemoveAttachClick(id)}
              >
                Delete
              </Button>
            </div>
          ))
        )}
      </section>
    </Skeleton>
  );
}
