
const isYearEqual = (dateBegin: Date, dateEnd: Date) => dateBegin.getFullYear() === dateEnd.getFullYear()
const isMonthEqual = (dateBegin: Date, dateEnd: Date) => dateBegin.getMonth() === dateEnd.getMonth()
const isDayEqual = (dateBegin: Date, dateEnd: Date) => dateBegin.getDate() === dateEnd.getDate()

export const isTomorrow = (dateCheck: Date) => {
  const dateTomorrow = new Date()
  dateTomorrow.setDate(dateTomorrow.getDate() + 1)

  return isYearEqual(dateTomorrow, dateCheck) &&
    isMonthEqual(dateTomorrow, dateCheck) &&
    isDayEqual(dateTomorrow, dateCheck)
}