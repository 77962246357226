import {
  CloseCircleOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import ReactDom from 'react-dom';

interface MediaFile {
  id: string;
  name: string;
  path: string;
  type: 'video' | 'photo';
}

interface Props {
  files: MediaFile[];
  currentId: string;
  onChange: (currentId: string) => void;
  onRotate?: (rotation: { angle: number; id: string }) => void;
}

export function MediaViewer({ files, currentId, onChange }: Props) {
  const rootNode = React.useRef(
    (() => {
      const node = document.createElement('div');
      document.body.appendChild(node);
      return node;
    })()
  );

  React.useEffect(() => {
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, []);

  React.useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      e.preventDefault();
      if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
        onBack();
      } else if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
        onNext();
      } else if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keyup', handleKey);
    return () => document.removeEventListener('keyup', handleKey);
  });

  // easy way to auto pause video on change active media
  React.useEffect(() => {
    Array.from(document.querySelectorAll('video'), it => {
      if (!it.paused) it.pause();
      return it;
    });
  }, [currentId]);

  const onBack = () => {
    const index = files.findIndex(it => it.id.toString() === currentId);
    if (index > 0) {
      onChange(files[index - 1].id.toString());
    }
  };

  const onNext = () => {
    const index = files.findIndex(it => it.id.toString() === currentId);
    if (index < files.length - 1) {
      onChange(files[index + 1].id.toString());
    }
  };

  const onClose = () => onChange('');

  return ReactDom.createPortal(
    <div
      onClick={onClose}
      style={{
        backgroundColor: '#00000060',
        position: 'fixed',
        inset: 0,
        zIndex: 9998,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ol onClick={e => e.stopPropagation()}>
        {files.map((mediaFile, i, self) => {
          const hasNext = i < self.length - 1;
          const hasPrevious = Boolean(i);

          return (
            <li
              key={`media:${currentId}`}
              style={{
                display:
                  currentId === mediaFile.id.toString() ? 'flex' : 'none',
                gap: 16,
              }}
              className="items-center"
            >
              <NavButton
                disabled={!hasPrevious}
                onClick={onBack}
                icon={<LeftOutlined />}
                style={{ left: 24 }}
              />

              <MediaFileItem file={mediaFile} onClose={onClose} />

              <NavButton
                onClick={onNext}
                icon={<RightOutlined />}
                disabled={!hasNext}
                style={{ right: 24 }}
              />
            </li>
          );
        })}
      </ol>
    </div>,
    rootNode.current
  );
}

function MediaFileItem({
  file,
  onClose,
}: {
  file: MediaFile;
  onClose: () => void;
}) {
  const { rotationAngle, handleRotateLeft, handleRotateRight } =
    useRotatationAngle(file);
  const { imageScale, x, y, handleZoomIn, handleZoomOut } = useImageScale();

  return (
    <>
      <div
        className="absolute flex items-center justify-end"
        style={{
          height: 56,
          background: '#00000065',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 9999,
          gap: 16,
          paddingRight: 16,
        }}
      >
        {file.type === 'photo' && (
          <>
            <ActionButton onClick={handleZoomOut} icon={<ZoomOutOutlined />} />
            <ActionButton onClick={handleZoomIn} icon={<ZoomInOutlined />} />
            <ActionButton
              onClick={handleRotateLeft}
              icon={<RotateLeftOutlined />}
              // disabled={isSubmittingRotation}
            />
            <ActionButton
              onClick={handleRotateRight}
              icon={<RotateRightOutlined />}
              // disabled={isSubmittingRotation}
            />
          </>
        )}

        <ActionButton onClick={onClose} icon={<CloseCircleOutlined />} />
      </div>

      {file.type === 'video' ? (
        <video
          aria-label={`Vídeo ${file.name}`}
          src={process.env.REACT_APP_UPLOAD_BUCKET?.concat(file.path)}
          controls
        />
      ) : (
        <img
          src={process.env.REACT_APP_UPLOAD_BUCKET?.concat(file.path)}
          alt={'Foto '.concat(file.name)}
          style={{
            transform: `rotate(${rotationAngle}deg) scale(${imageScale}) translate(${x}px, ${y}px)`,
            minHeight: '100vh',
          }}
        />
      )}
    </>
  );
}

function ActionButton({ icon, disabled, ...props }: any) {
  return (
    <button
      type="button"
      {...props}
      disabled={disabled}
      style={{
        cursor: disabled ? 'default' : 'pointer',
        color: disabled ? 'lightgray' : 'white',
        background: 'transparent',
        border: 'none',
      }}
    >
      {icon}
    </button>
  );
}

function NavButton({ onClick, icon, ...props }: any) {
  return (
    <Button
      className="absolute rounded-full z-10"
      type="primary"
      size="large"
      icon={icon}
      htmlType="button"
      onClick={onClick}
      {...props}
    />
  );
}

function useRotatationAngle(photo: { id: string }, onRotate?: any) {
  const [rotationAngle, setRotationAngle] = useState(0);

  React.useEffect(() => {
    setRotationAngle(0);
  }, [photo]);

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (!isFirstRender.current) {
      onRotate?.({ angle: 90, id: photo.id.toString() });
    } else {
      isFirstRender.current = false;
    }
  }, [rotationAngle]);

  const handleRotateLeft = () => {
    setRotationAngle(angle => angle - 90);
  };
  const handleRotateRight = () => {
    setRotationAngle(angle => angle + 90);
  };

  return { rotationAngle, handleRotateLeft, handleRotateRight };
}

function useImageScale() {
  const [imageScale, setImageScale] = useState(1);

  const getNextScale = React.useCallback(
    (sign: number) => {
      const isMinus = sign < 0 && imageScale > 1;
      const isPlus = sign > 0 && imageScale < 3;
      if (isMinus) {
        return imageScale - 0.5;
      } else if (isPlus) {
        return imageScale + 0.5;
      }
      return imageScale;
    },
    [imageScale]
  );

  const handleZoom = React.useCallback((nextImageScale: number) => {
    setImageScale(nextImageScale);
  }, []);
  const handleZoomOut = React.useCallback(() => {
    handleZoom(getNextScale(-1));
  }, [getNextScale, handleZoom]);
  const handleZoomIn = React.useCallback(() => {
    handleZoom(getNextScale(1));
  }, [getNextScale, handleZoom]);

  const [{ x, y }, setPointsState] = React.useState({
    x: 0,
    y: 0,
  });
  const pointsRef = React.useRef({ x: 0, y: 0 });
  const isPanning = React.useRef(false);

  React.useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      pointsRef.current = { x: e.clientX - x, y: e.clientY - y };
      isPanning.current = true;
    };

    const onMouseUp = () => {
      isPanning.current = false;
    };

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (!isPanning.current) return;
      setPointsState({
        x: e.clientX - pointsRef.current.x,
        y: e.clientY - pointsRef.current.y,
      });
    };

    const onWheelMove = (e: WheelEvent) => {
      const xs = (e.clientX - x) / imageScale;
      const ys = (e.clientY - y) / imageScale;
      const nextImageScale = getNextScale(e.deltaY < 0 ? 1 : -1);

      if (nextImageScale == 1) {
        setPointsState({
          x: 0,
          y: 0,
        });
      }

      handleZoom(nextImageScale);
    };
    document.addEventListener('wheel', onWheelMove);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('wheel', onWheelMove);
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, [handleZoom, x, y, imageScale, getNextScale]);

  return { imageScale, x, y, handleZoomIn, handleZoomOut };
}
