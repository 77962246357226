import { notification } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';

type BuildType = {
  headerName: string;
  baseUrl: string;
};

const buildAxiosIntance = (params: BuildType) => {
  const baseUrl = params.baseUrl ?? process.env.REACT_APP_PERSIST_SERVER;
  const headerName = params.headerName ?? 'x-access-token';
  const axiosInstance = axios.create({
    baseURL: baseUrl + '',
  });

  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (!!localStorage.getItem(`${process.env.REACT_APP_SESSION_KEY}`)) {
      const token = localStorage.getItem(
        `${process.env.REACT_APP_SESSION_KEY}`
      );
      if (config.headers) config.headers[headerName] = `${token}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(undefined, (error) => {
    if (error.response) {
      if (error.response.status === 400 || error.response.status === 500) {
        notification.error({
          message: error.response.data.error,
        });
      }
    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

const axiosInstance = buildAxiosIntance({
  headerName: 'x-access-token',
  baseUrl: process.env.REACT_APP_PERSIST_SERVER + '',
});

const axiosInstanceSync = buildAxiosIntance({
  headerName: 'Authorization',
  baseUrl: process.env.REACT_APP_VISTORIA_SYNC + '',
});

export { axiosInstance, axiosInstanceSync };


// import { notification } from "antd";
// import axios, { AxiosRequestConfig } from "axios";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_PERSIST_SERVER + "",
// });

// axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
//   if (!!localStorage.getItem(`${process.env.REACT_APP_SESSION_KEY}`)) {
//     const token = localStorage.getItem(`${process.env.REACT_APP_SESSION_KEY}`);
//     if (config.headers) {
//       config.headers["x-access-token"] = `${token}`;
//     }
//   }

//   return config;
// });

// axiosInstance.interceptors.response.use(undefined, (error) => {
//   if (error.response) {
//     if (error.response.status === 400 || error.response.status === 500) {
//       notification.error({
//         message: error.response.data.error,
//       });
//     }
//   }
//   return Promise.reject(error);
// });

// export default axiosInstance;
