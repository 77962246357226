import { gql } from '@apollo/client';

const GET_CURRENT_PLAN_ENTERPRISE_CLIENT = gql`
  query GetCurrent($enterpriseId: String!) {
    getCurrentPlanEnterpriseClient(enterpriseId: $enterpriseId) {
      id
      balance_metreage
      price_plan
      date_expire
      created_at
    }
  }
`;

export interface IGetCurrentPlanEnterpriseClient {
  getCurrentPlanEnterpriseClient: {
    id: string;
    balance_metreage: number;
    date_expire: string;
    created_at: string;
    price_plan: number;
  };
}

export default GET_CURRENT_PLAN_ENTERPRISE_CLIENT;
