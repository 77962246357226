import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Typography,
  Form,
  Switch,
  Select,
} from 'antd';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Values } from '.';

interface Props {
  onFilter: (params: Partial<Values>) => any;
}

const styles = {
  label: 'font-medium text-base flex flex-col',
};

export default function Filters({ onFilter }: Props) {
  const [searchParams] = useSearchParams();
  const [values, setValues] = useState(
    Object.fromEntries(searchParams.entries()) as Partial<Values>
  );

  const handleChange = (e: any, name: keyof Values) => {
    let newValue = e?.target ? e.target.value : e;

    if (!newValue) {
      setValues(prev => {
        const next = { ...prev };
        delete next[name];
        return next;
      });
    }

    setValues(prev => ({ ...prev, [name]: newValue }));
  };

  const handleClean = () => {
    setValues({});
  };

  const mounted = React.useRef(false);
  useLayoutEffect(() => {
    if (mounted.current) {
      onFilter(values);
    } else {
      mounted.current = true;
    }
  }, [onFilter, values]);

  return (
    <div>
      <div className="flex items-baseline justify-between">
        <Typography.Title level={4}>Filtrar</Typography.Title>
        <Button onClick={handleClean}>Limpar filtros</Button>
      </div>

      <Row className="gap-4 mb-2">
        <Col className="flex flex-col gap-y-6">
          <div className="w-52">
            <label className={styles.label}>Número do Pedido</label>
            <Input
              className="mb-2"
              type="text"
              placeholder="Insira número do pedido"
              value={values.numberRequestInternal}
              onChange={e => handleChange(e, 'numberRequestInternal')}
            />
          </div>
          <div className="w-52">
            <label className={styles.label}>
              Data de solicitação
              <DatePicker
                className="mb-2"
                value={
                  values.date_solicitation
                    ? moment(values.date_solicitation)
                    : undefined
                }
                onChange={moment =>
                  handleChange(
                    moment ? moment.toISOString() : '',
                    'date_solicitation'
                  )
                }
              />
            </label>
          </div>
          <div className="w-52">
            <label className={styles.label}>
              Data do agendamento
              <DatePicker
                className="mb-2"
                value={
                  values.date_inspection
                    ? moment(values.date_inspection)
                    : undefined
                }
                onChange={moment =>
                  handleChange(
                    moment ? moment.toISOString() : '',
                    'date_inspection'
                  )
                }
              />
            </label>
          </div>
          {/* <div className="w-52">
          <label className={styles.label}>Data da vistoria</label>
          <DatePicker
            value={
              values.date_solicitation
                ? moment(values.date_solicitation)
                : undefined
            }
            onChange={moment =>
              handleChange(
                moment ? moment.toISOString() : '',
                'date_solicitation'
              )
            }
          />
        </div> */}
          <div className="w-52">
            <label className={styles.label}>
              Status
              <Select
                className="grid mb-2"
                placeholder="Insira status"
                value={values.status}
                onChange={e => handleChange(e, 'status')}
                options={[
                  { label: 'Agendado', value: 'scheduled' },
                  { label: 'Realizado', value: 'inspected' },
                  { label: 'Finalizado', value: 'finished' },
                  { label: 'Contestado', value: 'contested' },
                  { label: 'Respondido', value: 'answered' },
                  { label: 'Cancelado', value: 'canceled' },
                ]}
              />
            </label>
          </div>
          <div className="w-52">
            <label className={styles.label}>Endereço</label>
            <Input
              className="mb-2"
              type="text"
              value={values.addressInspection}
              onChange={e => handleChange(e, 'addressInspection')}
              placeholder="Insira o endereço"
            />
          </div>
        </Col>

        <Col className="flex flex-col gap-y-6">
          <div className="w-52">
            <label className={styles.label}>Bairro</label>
            <Input
              className="mb-2"
              type="text"
              value={values.neighborhoodInspection}
              onChange={e => handleChange(e, 'neighborhoodInspection')}
              placeholder="Insira o bairro"
            />
          </div>
          <div className="w-52">
            <label className={styles.label}>Cidade</label>
            <Input
              className="mb-2"
              type="text"
              value={values.cityInspection}
              onChange={e => handleChange(e, 'cityInspection')}
              placeholder="Insira a cidade"
            />
          </div>
          <div className="w-52">
            <label className={styles.label}>Estado</label>
            <Input
              className="mb-2"
              type="text"
              value={values.stateInspection}
              onChange={e => handleChange(e, 'stateInspection')}
              placeholder="Insira o estado"
            />
          </div>

          <div className="w-52">
            <label className={styles.label}>Solicitante</label>
            <Input
              className="mb-2"
              type="text"
              value={values.userName}
              onChange={e => handleChange(e, 'userName')}
              placeholder="Insira o solicitante"
            />
          </div>
          <div className="w-52">
            <label className={styles.label}>Contestação Respondida</label>
            <Form.Item name="isContestationDone">
              <Switch
                unCheckedChildren="Não"
                checkedChildren="Sim"
                onChange={e => handleChange(e, 'isContestationDone')}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
}
