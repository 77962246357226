import {
  Divider,
  Typography,
  Alert,
  Form,
  Button,
  Input,
  notification,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useState, useRef, useEffect } from 'react';

import OrderService from '../../common/interfaces/OrderService';
import { updateOrderServiceObservation } from '../../services/http/order-service';

interface Props {
  className?: string;
  divider?: string;
  orderService: OrderService;
  onUpdate?: () => void;
}

const { Title } = Typography;

export function Description({
  className,
  divider,
  orderService,
  onUpdate,
}: Props) {
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [form] = Form.useForm();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isFirstRender = useRef(true);

  const shouldAllowEdit = ['A realizar', 'Agendado'].includes(
    orderService.status
  );
  const [isEditMode, setEditMode] = useState(() => {
    if (shouldAllowEdit) {
      return !orderService.description_client;
    }
    return false;
  });
  useEffect(() => {
    if (isEditMode && !isFirstRender.current) {
      inputRef.current?.focus();
    }
    isFirstRender.current = false;
  }, [isEditMode]);

  const handleSave = async () => {
    setLoadingUpdate(true);
    try {
      await updateOrderServiceObservation({
        id: orderService.id,
        ...form.getFieldsValue(),
      });
      onUpdate?.();
      notification.success({
        message: 'Observação atualizada!',
      });
      setEditMode(false);
    } catch (error: any) {
      notification.error({
        message: 'Erro ao atualizar observação!',
        description: error?.toString?.(),
      });
    } finally {
      setLoadingUpdate(false);
    }
  };

  return (
    <Form className={className} form={form} onFinish={handleSave}>
      <div className="flex items-center" style={{ height: 32 }}>
        <Title style={{ margin: 0 }} level={4}>
          Observação
        </Title>

        {shouldAllowEdit && !isEditMode && (
          <Button
            type="link"
            title="Editar"
            className="flex items-center justify-center"
            onClick={() => setEditMode(true)}
          >
            <EditOutlined size={32} />
          </Button>
        )}
      </div>
      <Divider className={divider} />

      <div
        className="flex flex-col"
        style={{ display: isEditMode ? undefined : 'none' }}
      >
        <Form.Item name="description">
          <Input.TextArea
            ref={inputRef}
            defaultValue={orderService.description_client}
          />
        </Form.Item>
        <div className="ml-auto">
          <Button
            htmlType="button"
            onClick={() => setEditMode(false)}
            className="mr-2"
          >
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" loading={loadingUpdate}>
            Salvar
          </Button>
        </div>
      </div>

      <Alert
        style={{ display: isEditMode ? 'none' : undefined }}
        type="info"
        message={orderService?.description_client}
      />
    </Form>
  );
}
