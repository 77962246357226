import { gql, useQuery } from '@apollo/client';

const GET_ENTERPRISE_CLIENT = gql`
  query GetEnterpriseInfo($enterpriseId: Int) {
    enterpriseInfo: getEnterpriseInfoClient(enterpriseId: $enterpriseId) {
      id
      name
      email
      cnpj
      phone
      addressFull {
        address
        number
        neighborhood
        city
        state
      }
    }
  }
`;

export interface GetEnterpriseInfo {
  enterpriseInfo: {
    id: string;
    name: string;
    email: string;
    cnpj: string;
    phone: string;
    addressFull: {
      address: string;
      number: string;
      neighborhood: string;
      city: string;
      state: string;
    };
  };
}

interface Variables {
  enterpriseId: number;
}

export function useEnterpriseInfo({ enterpriseId }: Variables) {
  return useQuery(GET_ENTERPRISE_CLIENT, { variables: { enterpriseId } });
}
