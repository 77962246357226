import { useQuery } from '@apollo/client';
import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { format } from 'date-fns';
import GET_CURRENT_PLAN_ENTERPRISE_CLIENT, {
  IGetCurrentPlanEnterpriseClient,
} from '../../common/graphql/GetCurrentPlanEnterpriseClient';
import { useAuth } from '../../contexts/auth';

import { Info } from './index';

const { Title, Text } = Typography;

interface Props {
  className?: string | undefined;
  divider?: string | undefined;
}

export default function Plan({ className, divider }: Props) {
  const { user } = useAuth();
  const { data: planData, loading: planLoading } =
    useQuery<IGetCurrentPlanEnterpriseClient>(
      GET_CURRENT_PLAN_ENTERPRISE_CLIENT,
      { 
        variables: { enterpriseId: user?.enterpriseId },
        context: {
          uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
        },
      }
    );

  const currentPlan = !planData?.getCurrentPlanEnterpriseClient
    ? undefined
    : {
        id: planData.getCurrentPlanEnterpriseClient.id,
        metreage: planData.getCurrentPlanEnterpriseClient.balance_metreage,
        name: 'Pacote',
        price_metreage:
          planData.getCurrentPlanEnterpriseClient.price_plan,
        date_expire: planData.getCurrentPlanEnterpriseClient.date_expire,
        created_at: planData.getCurrentPlanEnterpriseClient.created_at,
      };

  return (
    <Skeleton loading={planLoading}>
      <Title level={4}>Meu pacote</Title>
      <Divider className={divider} />
      {currentPlan && (
        <section className={className}>
          <Title level={5}>Pacote</Title>
          <Row align="middle" gutter={[16, 16]} className="gap-3">
            {packInfo.map(({ title, prop, as }) => (
              <Col key={prop} span={5}>
                <Title level={5} type="secondary">
                  {title}
                </Title>
                <Text strong>
                  {prop === 'price_metreage' && 'R$ '}
                  {(!as ? currentPlan[prop] : as(currentPlan[prop])) ??
                    'Indeterminado'}
                  {prop === 'metreage' && (
                    <span>
                      m<sup>2</sup>
                    </span>
                  )}
                </Text>
              </Col>
            ))}
          </Row>
        </section>
      )}
    </Skeleton>
  );
}

const packInfo: (Info & {
  prop: 'metreage' | 'price_metreage' | 'date_expire' | 'created_at';
  as?: (value: any) => any;
})[] = [
  { title: 'Metragem do pacote', prop: 'metreage' },
  {
    title: (
      <span>
        Valor por m<sup>2</sup>
      </span>
    ),
    prop: 'price_metreage',
  },
  {
    title: 'Quando expira',
    prop: 'date_expire',
    as(date: string) {
      if (date) {
        return format(Date.parse(date), 'dd/MM/yyyy');
      }
      return date;
    },
  },
  { title: 'Iniciado em', prop: 'created_at' },
];
