import { Reducer } from 'redux';
import { OrderServiceState, ServiceOrderTypes } from './types';
const INITIAL_OS: OrderServiceState = {
  data: null,
  formsValidation: [],
};

const reducer: Reducer<OrderServiceState> = (state = INITIAL_OS, action) => {
  switch (action.type) {
    case ServiceOrderTypes.UPDATE_OS:
      return { ...state, data: { ...state.data, ...action.payload } };
    case ServiceOrderTypes.CLEAR_OS:
      return { ...state, data: null };
    case ServiceOrderTypes.UPDATE_FORMS_VALIDATION:
      return {
        ...state,
        formsValidation: [...action.payload, ...state.formsValidation].filter(
          (a, index, all) =>
            all.findIndex(item => item.form === a.form) === index
        ),
      };
    case ServiceOrderTypes.CLEAR_VALIDATION:
      return { ...state, formsValidation: [] };
    default:
      return state;
  }
};

export default reducer;
