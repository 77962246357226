import { Col, Divider, Row, Typography } from 'antd';
import ConsumptionMetrage from '../../common/interfaces/ConsumptionMetreage';
import OrderService from '../../common/interfaces/OrderService';

interface Props {
  orderService: OrderService;
  className: string | undefined;
  divider: string | undefined;
}

const { Title, Paragraph } = Typography;

export default function Consumption({
  orderService,
  className,
  divider,
}: Props) {
  return (
    <section className={className}>
      <Title level={4}>
        Consumo m<sup>2</sup>
      </Title>
      <Divider className={divider} />
      <Row wrap={false} className="gap-3 items-baseline">
        <Col>
          {consumption.map(({ key }) => (
            <Title level={5} key={key}>
              {key}
            </Title>
          ))}
          <Title level={5}>Mobiliado ou semi-mobiliado</Title>
        </Col>
        <Col>
          {orderService &&
            consumption.map(i => {
              const value =
                i.value !== 'metreage'
                  ? orderService.consumptionMetreage[i.value]
                  : orderService.metreage;

              return (
                <Title key={i.key} level={5} type="secondary">
                  {typeof value === 'number' ? (
                    <>
                      {value}m<sup>2</sup>
                    </>
                  ) : (
                    'Não informado'
                  )}
                </Title>
              );
            })}
            <Title level={5} type="secondary">
              {orderService.have_furniture && (
                <>{orderService.consumptionMetreage['metreage_immmobile_have_furniture']}m<sup>2</sup></>
              )}
              {orderService.have_semi_furniture && (
                <>{orderService.consumptionMetreage['metreage_immmobile_have_semi_furniture']}m<sup>2</sup></>
              )}
              {!orderService.have_furniture && !orderService.have_semi_furniture && (<>0m<sup>2</sup></>)}
            </Title>
        </Col>
      </Row>
      <Divider className="my-0" />
      <div className="flex items-baseline gap-4">
        <Title level={5}>Consumo total:</Title>
        <Title level={5} type="secondary">
          {orderService && !orderService.date_canceled && orderService?.planEnterpriseInvoice?.metreage}
          {orderService && orderService.date_canceled && ('50')}
          m²
        </Title>
      </div>
      <div className="flex items-baseline gap-4">
        <Paragraph type="secondary" strong>
          Valor: R$ {orderService?.planEnterpriseInvoice && orderService?.planEnterpriseInvoice?.price}
        </Paragraph>
      </div>
    </section>
  );
}

const consumption: {
  key: string;
  value: keyof ConsumptionMetrage | 'metreage';
}[] = [
  { key: 'Área base de calculo', value: 'metreage_base' },
  { key: 'Tempo de entrega do relatório', value: 'metreage_delivery_report' },
  { key: 'Tipo de imóvel', value: 'metreage_immobile_type' },
  { key: 'Tipo de vistoria', value: 'metreage_inspection_type' },
  { key: 'Deslocamento', value: 'metreage_distance' },
  { key: 'Tempo de espera', value: 'metreage_time_waiting' },
];
