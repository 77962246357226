import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, notification } from 'antd';
import React from 'react';

import { axiosInstanceSync } from '../../services/axios';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';

interface Props {
  open: boolean;
  onClose: () => void;
  onSent: () => void;
}

export function NetPromoterScore({ open, onClose, onSent }: Props) {
  const { user } = useAuth();

  const [comment, setComment] = React.useState('');
  const [selectedRating, setSelectedRating] = React.useState<number>();

  const onRatingClick = (r: number) => setSelectedRating(r);

  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const onSendFeedback = async () => {
    setLoading(true);
    try {
      await axiosInstanceSync.post(
        `/api/enterprise/${user?.enterpriseId}/save-search-nps`,
        { rating: selectedRating, description: comment },
        { baseURL: process.env.REACT_APP_API_VISTORIA ?? '' }
      );
      setSent(true);
      onSent();
    } catch (error) {
      const description = axios.isAxiosError(error)
        ? error.message
        : error instanceof Error
        ? error.message
        : 'Falha ao enviar';
      notification.error({ message: 'Erro de servidor', description });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="bottom"
      height={272}
      headerStyle={{ height: 0, border: 'none' }}
      closeIcon={null}
    >
      <button
        type="button"
        onClick={onClose}
        className="absolute right-4 top-4 h-9 w-9 flex items-center justify-center rounded-full border border-gray-200"
      >
        <CloseOutlined style={{ fontSize: 16, color: 'gray' }} />
      </button>

      {sent ? (
        <div className="h-full flex flex-col justify-center">
          <p className="text-lg font-semibold text-center mb-6">
            Obrigado pelo feedback! A B.House continuará melhorando graças a você.
            💙
          </p>
          {/* <p className="text-center text-base mb-5">
            Gostaria de compartilhar seu comentário positivo?
          </p> */}
          <div className="flex items-center justify-center gap-2">
            <Button size="large" type="default" onClick={onClose}>
              Finalizar
            </Button>
            {/* <Button size="large" type="default" onClick={onClose}>
              Não, obrigado...
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigator.share({ title: 'Recomendo BHVP', text: comment });
              }}
            >
              Compartilhar
            </Button> */}
          </div>
        </div>
      ) : (
        <>
          <p className="text-lg font-semibold text-center mb-6">
            Vamos trabalhar juntos para fazer a B.House ainda melhor!🤝 <br />
            Qual a probabilidade de você indicar a B.House para um amigo ou
            parente?
          </p>
          <div
            className="mx-auto mb-4 flex items-center"
            style={{ maxWidth: 700 }}
          >
            <Input.TextArea
              style={{ height: 56 }}
              placeholder="Conte-nos algo que faz você voltar. Ou algo que possa levar isso a outro nível 🚀"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
            <Button
              style={{ height: 56 }}
              type="primary"
              disabled={!(comment && Number.isInteger(selectedRating))}
              onClick={onSendFeedback}
              loading={loading}
            >
              Enviar
            </Button>
          </div>
          <div className="flex items-center justify-center gap-2">
            <span className="italic">Bem improvável</span>
            {Array.from({ length: 11 }, (_, i) => {
              const isActive = selectedRating === i;
              return (
                <Button
                  key={`button:${i}`}
                  className="rounded-full h-10 w-10"
                  type={isActive ? 'primary' : 'default'}
                  onClick={() => onRatingClick(i)}
                >
                  {i}
                </Button>
              );
            })}
            <span className="italic">Extremamente provável</span>
          </div>
        </>
      )}
    </Drawer>
  );
}
