import { useLazyQuery } from '@apollo/client';
import { Col, Divider, Row, Typography } from 'antd';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import GET_OS_FULL_CLIENT from '../../common/graphql/GetOSFullClient';
import OrderService from '../../common/interfaces/OrderService';

import Addresses from './Addresses';
import Attachments from './Attachments';
import Consumption from './Consumption';
import { Description } from './Description';
import InspectionData from './Inspection';
import { MediaFiles } from './MediaFiles';

const { Title, Paragraph, Text } = Typography;

const styles = {
  section: 'mb-8',
  divider: 'mt-2 mb-4',
};

export default function OSOverview() {
  const params = useParams();
  const [getOS, { data, refetch }] = useLazyQuery(GET_OS_FULL_CLIENT, {
    fetchPolicy: 'cache-and-network',
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });

  useEffect(() => {
    if (params?.id) {
      getOS({ variables: { id: params?.id } });
    }
  }, [params?.id, getOS]);

  const orderService: OrderService = data?.getOrderServiceClient;

  if (!params?.id) return <Navigate replace to="/my-requests" />;

  if (!orderService) {
    return (
      <Title level={5} className="flex justify-center items-center">
        Carregando...
      </Title>
    );
  }

  return (
    <section className="p-4">
      <Title level={2}>
        Visão Geral - OS Nº {orderService?.number_request_internal}
      </Title>
      <Addresses
        orderService={orderService}
        className={styles.section}
        divider={styles.divider}
      />
      <InspectionData
        orderService={orderService}
        className={styles.section}
        divider={styles.divider}
      />
      <Description
        className={styles.section}
        divider={styles.divider}
        orderService={orderService}
        onUpdate={() => refetch()}
      />
      <Consumption
        orderService={orderService}
        className={styles.section}
        divider={styles.divider}
      />

      <section className={styles.section}>
        {/* -- */}
        <Title level={4}>Observacões correção:</Title>
        <Divider className={styles.divider} />
        <Row wrap={false} className="gap-3 items-baseline">
          <Col>
            <Typography.Text>
              {orderService?.orderServiceAppointment?.dependencies_description
                ? orderService.orderServiceAppointment.dependencies_description
                : 'Nenhuma observação informada'}
            </Typography.Text>
          </Col>
        </Row>
      </section>

      <section className={styles.section}>
        <Title level={4}>Locador</Title>
        {!orderService?.locators?.length ? (
          <Text strong>Não cadastrado</Text>
        ) : (
          orderService.locators.map(l => {
            return (
              <div className={l.id}>
                <Divider className={styles.divider} />
                <Paragraph type="secondary" strong>
                  {l.name} {l.last_name} {l.status_civil && ','}{' '}
                  {l.status_civil}
                  {l.nationality && ','} {l.nationality}
                </Paragraph>
                <Paragraph type="secondary" strong>
                  {l.cnpj ? 'CNPJ' : 'CPF'} {l.cnpj ? l.cnpj : l.cpf}
                </Paragraph>
              </div>
            );
          })
        )}
      </section>
      <section className={styles.section}>
        <Title level={4}>Locatário</Title>
        {!orderService?.tenants?.length ? (
          <Text strong>Não cadastrado</Text>
        ) : (
          orderService.tenants.map(t => (
            <div className={t.id}>
              <Divider className={styles.divider} />
              <Paragraph type="secondary" strong>
                {t.name} {t.last_name}
                {t.status_civil && ','} {t.status_civil}
                {t.nationality && ','}
                {t.nationality}
              </Paragraph>
              <Paragraph type="secondary" strong>
                {t.cnpj ? 'CNPJ' : 'CPF'} {t.cnpj ? t.cnpj : t.cpf}
              </Paragraph>
            </div>
          ))
        )}
      </section>
      <section className={styles.section}>
        <Title level={4}>Fiador</Title>
        {!orderService?.guarantors?.length ? (
          <Text strong>Não cadastrado</Text>
        ) : (
          orderService.guarantors.map(g => (
            <div className={g.id}>
              <Divider className={styles.divider} />
              <Paragraph type="secondary" strong>
                {g.name} {g.last_name}
                {g.status_civil && ','} {g.status_civil}
                {g.nationality && ','} {g.nationality}
              </Paragraph>
              <Paragraph type="secondary" strong>
                {g.cnpj ? 'CNPJ' : 'CPF'} {g.cnpj ? g.cnpj : g.cpf}
              </Paragraph>
            </div>
          ))
        )}
      </section>
      <section className={styles.section}>
        <Title level={4}>Testumanha</Title>
        {!orderService?.witness?.length ? (
          <Text strong>Não cadastrado</Text>
        ) : (
          orderService.witness.map(g => (
            <div className={g.id}>
              <Divider className={styles.divider} />
              <Paragraph type="secondary" strong>
                {g.name} {g.last_name}
                {g.status_civil && ','} {g.status_civil}
                {g.nationality && ','} {g.nationality}
              </Paragraph>
              <Paragraph type="secondary" strong>
                {g.cnpj ? 'CNPJ' : 'CPF'} {g.cnpj ? g.cnpj : g.cpf}
              </Paragraph>
            </div>
          ))
        )}
      </section>
      <Attachments orderServiceId={params.id} className={styles.section} />

      <MediaFiles orderService={orderService} className={styles.section} />
    </section>
  );
}
