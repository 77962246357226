import { gql, useQuery } from "@apollo/client";

const GET_UNAVAILABLE_DATES = gql`
  query GetUnavailableDates {
    unavailableDates: getDateInspectionUnavailable {
      date
      isHoliday
    }
  }
`;

interface GetUnavailableDates {
  unavailableDates: { date: string; isHoliday: boolean }[];
}

export function useUnavailableDates() {
  return useQuery<GetUnavailableDates>(GET_UNAVAILABLE_DATES, {
    fetchPolicy: "cache-first",
    context: { uri: process.env.REACT_APP_VISTORIA_FETCH_SYNC },
  });
}
