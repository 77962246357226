import { Col, Divider, Row, Typography } from 'antd';
import OrderService from '../../common/interfaces/OrderService';

interface Props {
  orderService: OrderService;
  className: string | undefined;
  divider: string | undefined;
}

const { Title } = Typography;

export default function Addresses({ orderService, className, divider }: Props) {
  return (
    <section className={className}>
      <Title level={4}>Endereços</Title>
      <Divider className={divider} />
      <Row className="items-baseline gap-8">
        <Col>
          {addresses.map(({ key }) => (
            <Title level={5} key={key}>
              {key}
            </Title>
          ))}
        </Col>
        <Col>
          {orderService &&
            addresses.map(i => {
              const address = orderService[i.value];

              return (
                <Title level={5} type="secondary" key={i.key}>
                  {address
                    ? [
                        (address.address ?? '') +
                          (address.number ? ` ${address.number}` : ''),
                        address.complement,
                        address.neighborhood,
                        address.city,
                        address.state,
                        address.zip_code,
                      ]
                        .filter(i => !!i)
                        .map((a, index) => (
                          <span key={a}>{!!index ? ', ' + a : a}</span>
                        ))
                    : 'Não informado'}
                </Title>
              );
            })}
        </Col>
      </Row>
    </section>
  );
}

const addresses: {
  key: string;
  value: 'address_inspection' | 'address_removal_key' | 'address_delivery_key';
}[] = [
  { key: 'Vistoria', value: 'address_inspection' },
  { key: 'Retirada das chaves', value: 'address_removal_key' },
  { key: 'Entrega das chaves', value: 'address_delivery_key' },
];
