import { InboxOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Typography, Button, notification, Upload, message } from 'antd';
import { DraggerProps, RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GET_OS_LIST from '../../../common/graphql/GetOSList';

import { ServiceOrderTypes } from '../../../common/redux/states/serviceOrder/types';
import { axiosInstanceSync } from '../../../services/axios';

interface Props {
  orderServiceId: string | undefined;
}

function AttachDragger({ orderServiceId }: Props) {
  const [files, setFiles] = useState<RcFile[]>([]),
    [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const setLocation = useNavigate();

  const handleAttach = () => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    if (orderServiceId) {
      setUploading(true);
      axiosInstanceSync
        .post(
          `/client/order-service/${orderServiceId}/upload-attachment`,
          formData
        )
        .then(() =>
          notification.success({
            message: 'Anexo concluído. Pedido finalizado!',
            description: 'Você será redirecionado para a lista de agendamentos',
            onClose: () => setLocation(`/my-requests`),
          })
        )
        .catch(() => notification.error({ message: 'Não foi possível anexar' }))
        .finally(() => setUploading(false));
    } else
      notification.error({
        message: 'Confirme a ordem de serviço para poder anexar!',
      });
  };

  const client = useApolloClient();
  // const { user } = useAuth();

  const handleConclude = () => {
    dispatch({
      type: ServiceOrderTypes.CLEAR_OS,
    });
    dispatch({
      type: ServiceOrderTypes.CLEAR_VALIDATION,
    });

    if (files.length) {
      handleAttach();
    } else {
      notification.success({
        message: 'Pedido finalizado com sucesso!',
        duration: 1,
        onClose: async () => {
          // await client.query({
          //   query: GET_OS_LIST_CLIENT,
          //   variables: {
          //     options: {
          //       limit: 10,
          //       orderBy: { order: 'DESC', column: 'date_solicitation' },
          //     },
          //     filter: {
          //       enterprise_id: user?.enterpriseId,
          //     },
          //   },
          // });
          await client.refetchQueries({ include: [GET_OS_LIST] });
          setLocation(`/my-requests`);
        },
      });
    }
  };

  const draggerProps: DraggerProps = {
    name: 'file',
    multiple: true,
    beforeUpload: file => {
      setFiles(list => [...list, file]);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} anexado com sucesso.`);
      } else if (status === 'error') {
        message.error(`Erro ao anexar ${info.file.name}`);
      }
    },
    onRemove(file) {
      setFiles(list => {
        const index = list.indexOf(file as RcFile);
        const newList = list.slice();
        newList.splice(index, 1);
        return newList;
      });
    },
  };

  return (
    <section className="p-4">
      <Typography.Title level={5}>
        Deseja enviar um anexo à solicitação da Ordem de Serviço?
      </Typography.Title>
      <Upload.Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Solte arquivos aqui, ou clique para selecioná-los.
        </p>
      </Upload.Dragger>
      <div className="flex justify-between gap-4 mt-4">
        <Button
          type="primary"
          loading={uploading}
          htmlType="button"
          onClick={handleConclude}
          block
        >
          Salvar arquivo anexado
        </Button>
      </div>
    </section>
  );
}

export default AttachDragger;
