import React from 'react';
import jwt_decode from 'jwt-decode';

import isBefore from 'date-fns/isBefore';
import {
  json,
  LoaderFunction,
  LoaderFunctionArgs,
  redirect,
} from 'react-router-dom';

export let withAuth: (loader?: LoaderFunction) => LoaderFunction | undefined = (
  loader = () => json(null)
) => {
  return (args: LoaderFunctionArgs) => {
    const token = localStorage.getItem(`${process.env.REACT_APP_SESSION_KEY}`);

    if (!token) {
      throw redirect('/login');
    }

    const decoded: any = jwt_decode(token);

    const expDate = decoded.exp * 1000;

    const isValid = isBefore(new Date(), new Date(expDate));
    if (!isValid) {
      throw redirect('/login');
    }

    return loader(args);
  };
};
