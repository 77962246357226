import { Steps } from 'antd';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { OrderServiceState, ServiceOrderTypes } from '../../../common/redux/states/serviceOrder/types';

import Attach from './Attach';
import Warning from './Warnings';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';

const Addresses = lazy(() => import('./Adresses'));
const Signers = lazy(() => import('./Signers'));
const PropertyInfo = lazy(() => import('./PropertyInfo'));
const InspectionInfo = lazy(() => import('./InspectionInfo'));
const Reviewer = lazy(() => import('./Reviewer'));

type StepStatus = 'wait' | 'process' | 'finish' | 'error';

interface RequestStepsProps {
  os: OrderServiceState;
}

interface IStep {
  title: any;
  content: any;
  status?: StepStatus;
  step: number;
  index: string;
}

const RequestSteps = ({ os }: RequestStepsProps) => {
  const dispatch = useDispatch();
  
  const [orderServiceId, setOSId] = useState<string>();
  const [currentStep, setCurrentStep] = useState(0);  

  useEffect(() => {
    dispatch({
      type: ServiceOrderTypes.CLEAR_OS,
    });

    dispatch({
      type: ServiceOrderTypes.CLEAR_VALIDATION,
    });
  }, [dispatch]);

  const nextStep = useCallback(
    () => setCurrentStep(current => current + 1),
    []
  );

  function getStepValidation(key: string) {
    const formStatusIndex = os.formsValidation.findIndex(i => i.form === key);

    if (formStatusIndex > -1) {
      return os.formsValidation[formStatusIndex].valid ? 'finish' : 'error';
    } else {
      return 'wait';
    }
  }

  const getStepStatus: (index: string, i: number) => StepStatus = (
    index,
    i
  ) => {
    if (i === os.formsValidation.length)
      return os.formsValidation[os.formsValidation.length - i]?.valid
        ? 'process'
        : 'wait';

    if (currentStep > i) return 'finish';

    return getStepValidation(index);
  };

  const steps: IStep[] = [
    {
      index: 'address',
      step: 1,
      title: (
        <>
          <span>Endereços</span> <span className="text-danger">*</span>
        </>
      ),
      content: <Addresses index="address" nextStep={nextStep} />,
    },
    {
      index: 'signers',
      step: 2,
      title: (
        <span
          title="Intervenientes/Signatários"
          aria-label="Intervenientes/Signatários"
        >
          Signatários
        </span>
      ),
      content: <Signers nextStep={nextStep} index="signers" />,
    },
    {
      index: 'property',
      step: 3,
      title: (
        <>
          <span>Dados do Imóvel</span>
          <span className="text-danger"> *</span>
        </>
      ),
      content: <PropertyInfo nextStep={nextStep} index="property" />,
    },
    {
      index: 'inspection',
      step: 4,
      title: (
        <>
          <span>Dados da vistoria</span>
          <span className="text-danger"> *</span>
        </>
      ),
      content: <InspectionInfo nextStep={nextStep} index="inspection" />,
    },
    {
      index: 'warnings',
      step: 5,
      title: 'Avisos',
      content: <Warning handleNext={nextStep} />,
    },
    {
      index: 'review',
      step: 6,
      title: 'Revisão da solicitação',
      content: <Reviewer {...{ handleNext: nextStep, setOSId }} />,
    },
    {
      index: 'attach',
      step: 7,
      title: 'Anexar',
      content: <Attach {...{ orderServiceId }} />,
    },
  ];

  return (
    <div className="mt-3">
      <Steps
        current={currentStep}
        onChange={setCurrentStep}
        type="navigation"
        size="small"
      >
        {steps.map(({ index, title }: IStep, i) => {
          return (
            <Steps.Step
              className={styles.step}
              status={
                getStepStatus(index, i) === 'wait' && currentStep === i
                  ? 'process'
                  : getStepStatus(index, i)
              }
              key={title}
              title={title}
              disabled={getStepStatus(index, i) === 'wait' && currentStep !== i}
            />
          );
        })}
      </Steps>

      <Suspense fallback={<h1>Carregando...</h1>}>
        <div className="bg-white border shadow-sm" >
          {steps[currentStep]?.content}
        </div>
      </Suspense>
    </div>
  );
};

export default RequestSteps;
