import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { updateUserPassword } from '../../services/http/user';

export function UserEditPassword() {
  const { user } = useAuth();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const handleSave = () => {
    setLoading(true);
    const values = form.getFieldsValue();

    updateUserPassword({
      ...values,
      id: user?.id,
    })
      .then(() => {
        notification.success({ message: 'Senha atualizada com sucesso!' });
        form.setFieldsValue({ password: '', confirmPassword: '' });
      })
      .catch(error => {
        notification.error({
          message: 'Erro ao atualizar senha',
          description: error?.toString?.(),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form form={form} onFinish={handleSave} layout="vertical">
      <Typography.Title level={4}>Alterar senha</Typography.Title>

      <Row gutter={16} align="middle">
        <Col span={8}>
          <Form.Item
            name="password"
            label={<Typography.Text strong>Senha</Typography.Text>}
          >
            <Input type="password" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="confirmPassword"
            label={
              <Typography.Text strong>Confirmação de Senha</Typography.Text>
            }
          >
            <Input type="password" />
          </Form.Item>
        </Col>
        <Col span={8} className="flex justify-end">
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
