import { Col, Input, Row, Typography, Form, notification, Button } from 'antd';
import { Divider, Select } from 'antd';
import { useAuth } from '../../contexts/auth';

import { axiosInstance } from '../../services/axios';

const Index = () => {
  const { user } = useAuth();
  const [form] = Form.useForm();

  async function submit(formData: { description: string }) {
    if (!user) return;

    await axiosInstance.post(
      `/client/enterprise/${user?.enterpriseId}/create-suggestion`,
      {
        name: user.name,
        email: user.email,
        description: formData.description,
      }
    );
    notification.success({ message: 'Sugestão enviada com sucesso!' });
    form.resetFields(['description', 'type']);
  }

  const { TextArea } = Input;
  return (
    <section className="p-4">
      <Typography.Title level={2}>
        Contato, sugestões e críticas
      </Typography.Title>
      <div>
        <Typography.Title level={4}>Contato</Typography.Title>
        <span className="flex gap-3">
          <strong>Email para contato:</strong>
          <a
            href="mailto:contato@bhvistoriapredial.com.br"
            target="_blank"
            rel="noreferrer"
          >
            contato@bhvistoriapredial.com.br
          </a>
        </span>
      </div>
      <Divider />
      <div>
        <Typography.Title level={4}>Sugestão / Crítica</Typography.Title>
        <Form
          validateTrigger="onBlur"
          onFinish={submit}
          form={form}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item shouldUpdate name="name" label="Nome">
                <Input
                  name="name"
                  value={user?.name}
                  placeholder={user?.name}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate name="email" label="Email">
                <Input
                  name="email"
                  placeholder={user?.email}
                  value={user?.email}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={'description'}
            rules={[{ required: true, message: 'Informe uma descrição' }]}
            label="Descrição"
          >
            <TextArea rows={6} />
          </Form.Item>
          <Form.Item name="type" label="Tipo">
            <Select>
              <Select.Option value="suggestion">Sugestão</Select.Option>
              <Select.Option value="criticism">Crítica</Select.Option>
            </Select>
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Enviar
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default Index;
