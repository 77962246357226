import React from 'react';
import { Modal, Form, Input, Button, Typography, notification } from 'antd';
import { axiosInstanceSync } from '../../services/axios';
import { phoneMask } from '../../utils/masks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CashbackModal = ({ open, onClose }: Props) => {
  const [indicating, setIndicating] = React.useState(false);

  const onCancel = () => {
    axiosInstanceSync.post(
      '/api/enterprise/send-cashback-form',
      { closeCashback: true },
      {
        baseURL: process.env.REACT_APP_API_VISTORIA,
      }
    );
    onClose();
  };

  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      {indicating ? (
        <IndicationForm onSubmitted={onClose} />
      ) : (
        <div>
          <h2 className="text-center text-lg mb-6">
            Ganhe <strong>Cashback</strong> na <strong>Indicação</strong>
          </h2>
          <h3 className="text-center text-4xl font-bold mb-10">
            <span className="px-3 align-middle bg-blue-100 text-blue-700">
              400 m²
            </span>
          </h3>

          <Typography.Paragraph className="text-center text-base" strong>
            Você indica uma imobiliária que também quer se beneficiar com as
            nossas vistorias e se ele fechar conosco você recebe cashback.
          </Typography.Paragraph>
          <Typography.Paragraph className="text-center text-base" strong>
            Um bônus em metragem para você utilizar.
          </Typography.Paragraph>

          <div className="flex items-center justify-center mb-3">
            <Button
              size="large"
              type="primary"
              htmlType="button"
              onClick={() => {
                setIndicating(true);
              }}
            >
              Indicar agora
            </Button>
          </div>

          <Typography.Paragraph
            className="text-center text-base"
            strong
            style={{ marginBottom: 0 }}
          >
            Aproveite!
          </Typography.Paragraph>
        </div>
      )}
    </Modal>
  );
};

interface IndicationFormProps {
  onSubmitted: () => void;
}

function IndicationForm({ onSubmitted }: IndicationFormProps) {
  const [form] = Form.useForm();

  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await axiosInstanceSync.post(
        '/api/enterprise/send-cashback-form',
        values,
        { baseURL: process.env.REACT_APP_API_VISTORIA }
      );
      onSubmitted();

      notification.success({
        message:
          'Indicação realizada com sucesso! Entraremos em contato com o indicado.',
        duration: 5 * 1000,
      });
    } catch (error) {
      notification.error({ message: 'Falha ao enviar! Tente novamente' });
    } finally {
      setLoading(false);
    }
  };

  const handleValuesChange = (changedValues: Record<string, any>) => {
    let _values: Record<string, string> = {};
    if (changedValues.phone) {
      _values.phone = phoneMask.onChange({
        target: { value: changedValues.phone },
      });
    }

    form.setFieldsValue({
      ...changedValues,
      ..._values,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={handleValuesChange}
    >
      <Typography.Title level={3}>Indicação</Typography.Title>

      <Form.Item
        label="Nome do indicado"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail do indicado"
        name="email"
        rules={[{ required: true }]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label="Nome imobiliária do indicado"
        name="clientName"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Cargo do indicado"
        name="role"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Telefone do indicado"
        name="phone"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Observação" name="description">
        <Input.TextArea />
      </Form.Item>

      <div className="flex gap-3 justify-end">
        {/* <Button htmlType="button">Voltar</Button> */}
        <Button type="primary" htmlType="submit" loading={loading}>
          Enviar
        </Button>
      </div>
    </Form>
  );
}
