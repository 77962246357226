import { gql, useQuery } from '@apollo/client';

const GET_ENVIRONMENT_MEDIA = gql`
  query GetEnvironmenMedia($environmentId: String) {
    environment: getEnvironmentInspection(id: $environmentId) {
      id
      photos {
        id
        name
        path
      }
      videos {
        id
        name
        path
      }
    }
  }
`;

interface MediaFile {
  id: string;
  name: string;
  path: string;
}

interface GetEnvironmentMedia {
  environment: {
    id: string;
    photos: Array<MediaFile>;
    videos: Array<MediaFile>;
  };
}

interface Variables {
  environmentId: string;
}

export function useEnvironmentMedia(variables: Variables) {
  return useQuery<GetEnvironmentMedia, Variables>(GET_ENVIRONMENT_MEDIA, {
    fetchPolicy: 'cache-first',
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
    variables,
  });
}
