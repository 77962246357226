import { Dropdown, Table, Menu, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { dateformatter } from '../../common/helpers/Formatter';
import { OrderService } from './index';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { RecordActivityEnterprisePlan } from '../../common/graphql/GetExtractEnterprisePlan';
import { TooltipContent } from './TooltipContent';

interface Props {
  dataSource: RecordActivityEnterprisePlan[] | undefined;
  loading: boolean;
}

export default function ConsumptionTable({ dataSource, loading }: Props) {
  const getDefaultPage = () => {
    const search = new URLSearchParams(window.location.search);
    const pageString = search.get('page');
    if (!pageString) return 1;
    return parseInt(pageString);
  };

  return (
    <>
      <Table
        pagination={{
          defaultCurrent: getDefaultPage(),
          onChange(page) {
            const params = new URLSearchParams(window.location.search);
            params.set('page', String(page));
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState(null, '', newUrl);
          },
        }}
        rowKey={s => s.id}
        {...{ columns, dataSource, loading }}
      />
    </>
  );
}

const columns: ColumnsType<RecordActivityEnterprisePlan> = [
  {
    title: 'Data',
    dataIndex: 'orderService',
    render: (o: OrderService, row: RecordActivityEnterprisePlan) => {
      const dateSolicitation = row?.orderServiceCanceled?.orderService
        ?.date_solicitation
        ? row.orderServiceCanceled.orderService.date_solicitation
        : row?.dateActivity
        ? row.dateActivity
        : o?.date_solicitation;

      if (!dateSolicitation) return '-';
      return dateformatter(dateSolicitation, 'dd/MM/yy HH:mm');
    },
    defaultSortOrder: 'descend',
    // sorter: (a, b) =>
    //   new Date(a.orderService.date_solicitation).getTime() -
    //   new Date(b.orderService.date_solicitation).getTime(),
  },
  {
    title: 'Nº Pedido',
    dataIndex: 'orderService',
    render: (o: OrderService, row: RecordActivityEnterprisePlan) => {
      const numberRequestInternal =
        row?.orderServiceCanceled?.orderService?.number_request_internal ??
        o?.number_request_internal;
      if (!numberRequestInternal) return '-';
      return numberRequestInternal;
    },
    // sorter: (a, b) =>
    //   new Date(a.orderService.date_solicitation).getTime() -
    //   new Date(b.orderService.date_solicitation).getTime(),
  },
  {
    title: 'Solicitante',
    dataIndex: 'orderService',
    render: (o: OrderService, row: RecordActivityEnterprisePlan) => {
      const userName =
        row?.orderServiceCanceled?.orderService?.user?.name ?? o?.user?.name;
      if (typeof userName !== 'string')
        return <span className="block text-center">-</span>;
      return userName
        ? userName.charAt(0).toUpperCase() + userName.slice(1)
        : '-';
    },
  },
  {
    title: 'Vistoriador',
    dataIndex: 'orderService',
    render: (o: OrderService, row: RecordActivityEnterprisePlan) => {
      const userName =
        row?.orderServiceCanceled?.orderService?.inspection?.inspector?.user
          ?.name ?? o?.user?.name;
      if (typeof userName !== 'string')
        return <span className="block text-center">-</span>;
      return userName
        ? userName.charAt(0).toUpperCase() + userName.slice(1)
        : '-';
    },
  },
  {
    title: 'Endereço da vistoria',
    dataIndex: 'orderService',
    render: (o: OrderService, row: RecordActivityEnterprisePlan) => {
      const addressInspection =
        row?.orderServiceCanceled?.orderService?.address_inspection ??
        o?.address_inspection;
      if (!addressInspection)
        return <span className="block text-center">-</span>;
      return `${addressInspection.address}, ${addressInspection.number}. ${addressInspection.complement} - ${addressInspection.neighborhood}`;
    },
  },
  {
    title: 'Atividade',
    dataIndex: 'activity',
    filters: [
      { text: 'Entrada', value: 'entrada' },
      { text: 'Saída', value: 'saída' },
      { text: 'Comparação', value: 'comparação' },
    ],
    onFilter: (
      value: string | number | boolean,
      { activity }: RecordActivityEnterprisePlan
    ) => typeof value === 'string' && activity.includes(value),
  },
  {
    title: 'Saldo anterior',
    dataIndex: 'balancePrevious',
    render: (balancePrevious: number) => {
      if (!balancePrevious) return <span className="block text-center">-</span>;
      return `${balancePrevious.toFixed(2)} m²`;
    },
  },
  {
    title: 'Consumo',
    dataIndex: 'consumptionMetreage',
    render: (consumptionMetreage: number) => {
      if (!consumptionMetreage)
        return <span className="block text-center">-</span>;
      return `${consumptionMetreage.toFixed(2)} m²`;
    },
  },
  {
    title: 'Acréscimo',
    dataIndex: 'additionMetreage',
    render: (additionMetreage: number, row: RecordActivityEnterprisePlan) => {
      const metreageAdd =
        additionMetreage ?? row?.enterprisePurchasePlan?.metreage;
      const justification = row?.planEnterpriseActiviy?.description;
      const user = row?.planEnterpriseActiviy?.user?.name;
      return metreageAdd ? (
        <Tooltip
          title="Motivo"
          color="#FFF"
          overlay={<TooltipContent user={user} justification={justification} />}
        >
          <p>{metreageAdd}m²</p>
        </Tooltip>
      ) : (
        <span className="block text-center">-</span>
      );
    },
  },
  {
    title: 'Decréscimo',
    dataIndex: 'decreaseMetreage',
    render: (sub: number, row: RecordActivityEnterprisePlan) => {
      const justification = row?.planEnterpriseActiviy?.description;
      const user = row?.planEnterpriseActiviy?.user?.name;
      return sub ? (
        <Tooltip
          title="Motivo"
          color="#FFF"
          overlay={<TooltipContent user={user} justification={justification} />}
        >
          <p>{sub}m²</p>
        </Tooltip>
      ) : (
        '-'
      );
    },
  },
  {
    title: 'Saldo do dia',
    dataIndex: 'balanceCurrent',
    render: (balanceCurrent: number) => {
      if (!balanceCurrent) return <span className="block text-center">-</span>;
      return `${balanceCurrent} m²`;
    },
  },
  {
    title: '',
    dataIndex: 'orderService',
    render: (os: OrderService, row: RecordActivityEnterprisePlan) => {
      const orderServiceId =
        row?.orderServiceCanceled?.orderService?.id ?? os?.id;
      if (!orderServiceId) return <span className="block text-center">-</span>;
      return (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key={orderServiceId}>
                <Link to={`/my-requests/${orderServiceId}`}>
                  Detalhes do pedido
                </Link>
              </Menu.Item>
            </Menu>
          }
        >
          <RightOutlined />
        </Dropdown>
      );
    },
  },
];
