import { axiosInstanceSync } from '../axios';

interface UpdateUserVariables {
  id: string;
  name: string;
  email: string;
  phone?: string;
  celphone?: string;
}

export function updateUser(variables: UpdateUserVariables) {
  return axiosInstanceSync.post(
    `/client/user/${variables.id}/update`,
    variables
  );
}

export function updateUserPassword(variables: {
  id: string;
  password: string;
  confirmPassword: string;
}) {
  return axiosInstanceSync.post(`/user/${variables.id}/change-password`, {
    password: variables.password,
    confirmPassword: variables.confirmPassword,
  });
}
