import {
  Col,
  Form,
  PageHeader,
  Row,
  Input,
  Typography,
  notification,
  Button,
  Divider,
  Skeleton,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { updateUser } from '../../services/http/user';
import { cellphoneMask, phoneMask } from '../../utils/masks';
import { UserEditPassword } from './UserEditPassword';
import { useUser } from '../../hooks/useUser';

const { Text } = Typography;

const userInfo = [
  { title: 'Nome', name: 'name' },
  { title: 'Email', name: 'email' },
  { title: 'Telefone', name: 'phone' },
];

export default function UserEdit() {
  const { user: localUser } = useAuth();

  if (!localUser) throw new Error('User does not exists');

  const { data, loading, refetch } = useUser({ id: localUser.id });
  const user = data?.user;

  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);

  const setLocation = useNavigate();
  const handleSave = () => {
    setLoadingSave(true);
    updateUser({ ...form.getFieldsValue(), id: user?.id })
      .then(() => {
        notification.success({
          message: 'Informações salvas!',
        });
        setLocation('/user');
        refetch();
      })
      .catch(error => {
        notification.error({
          message: 'Erro ao atualizar usuário',
          description: error?.toString?.(),
        });
      })
      .finally(() => setLoadingSave(false));
  };

  const handleValuesChange = (changedValues: Record<string, any>) => {
    let _values: Record<string, string> = {};
    if (changedValues.phone) {
      _values.phone = phoneMask.onChange({
        target: { value: changedValues.phone },
      });
    }
    if (changedValues.celphone) {
      _values.celphone = cellphoneMask.onChange({
        target: { value: changedValues.celphone },
      });
    }

    form.setFieldsValue({
      ...changedValues,
      ..._values,
    });
  };

  return (
    <div className="px-5">
      <PageHeader
        className="px-0"
        title="Editar Dados Pessoais"
        onBack={() => setLocation('/user')}
      />

      <Skeleton loading={loading && !user}>
        <Form
          onValuesChange={handleValuesChange}
          form={form}
          onFinish={handleSave}
          layout="vertical"
          initialValues={user}
        >
          <Row align="middle" gutter={[16, 0]}>
            {userInfo.map(i => {
              const name = i.name;
              const label = i.title;

              let type = 'text';
              if (i.name === 'email') type = 'email';

              return (
                <Col key={name} span={12}>
                  <Form.Item name={name} label={<Text strong>{label}</Text>}>
                    <Input type={type} />
                  </Form.Item>
                </Col>
              );
            })}

            <Col span={12} className="flex justify-end">
              <Button type="primary" htmlType="submit" loading={loadingSave}>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Skeleton>

      <Divider />

      <UserEditPassword />
    </div>
  );
}
