import React, { useContext, useState } from 'react';

type ContextType = {
  statement: boolean;
  setStatement: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StatementContext = React.createContext({} as ContextType);

export const StatementContextProvider: React.FC = ({ children }) => {
  const [statement, setStatement] = useState(false);

  const value = { statement, setStatement };

  return <StatementContext.Provider {...{ value, children }} />;
};

export function useStatement() {
  const context = useContext(StatementContext);
  return context;
}
