import React, { useContext, useState } from 'react';
import { redirect } from 'react-router-dom';
import AuthResponse from '../common/interfaces/AuthResponse';
import User from '../common/interfaces/User';
import { axiosInstance } from '../services/axios';

type AuthContextType = {
  user: User | null;
  signIn: (data: AuthRequest) => Promise<string>;
  signOut: () => void;
};
type AuthRequest = {
  username: string;
  password: string;
};

export const AuthContext = React.createContext({} as AuthContextType);

// const GET_USER_LOGGED = gql`
//   query getUserLogged($username: String!, $password: String!) {
//     loginClient(username: $username, password: $password) {
//       access_token
//       name
//       email
//       phone
//       celphone
//       enterpriseId
//     }
//   }
// `;

export function getUser() {
  return JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_USER_PROFILE_KEY}`) + ''
  ) as User | null;
}

export function requireUser() {
  const user = getUser();
  if (!user) throw redirect('/login');
  return user;
}

export const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(getUser);

  const signIn = React.useCallback(
    async ({ username, password }: AuthRequest) => {
      try {
        const { data } = await axiosInstance.post(
          '/api/user/login',
          { username, password },
          { baseURL: process.env.REACT_APP_API_VISTORIA }
        );
        if (data) {
          const { access_token, ...user } = data as AuthResponse;
          setUser(user);
          localStorage.setItem(
            `${process.env.REACT_APP_SESSION_KEY}`,
            access_token
          );
          localStorage.setItem(
            `${process.env.REACT_APP_USER_PROFILE_KEY}`,
            JSON.stringify(user)
          );
        }

        return '';
      } catch (err: any) {
        const status = err.response.status;
        if (status === 401) {
          return 'Nome ou senha incorretos!';
        } else if (status !== 200) {
          return 'Algo inesperado aconteceu!';
        } else {
          return '';
        }
      }
    },
    []
  );

  const signOut = () => {
    localStorage.clear();
    setUser(null);
  };

  const value = { user, signIn, signOut };

  return <AuthContext.Provider {...{ value, children }} />;
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context.user && window.location.pathname !== '/login') {
    window.location.pathname = '/login';
  }

  return context;
}
