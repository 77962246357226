import { gql, useQuery } from '@apollo/client';

const GET_USER = gql`
  query GetUser($id: String) {
    user: getUser(id: $id) {
      id
      name
      email
      phone
      celphone
    }
  }
`;

interface GetUser {
  user: {
    id: string;
    name: string;
    email: string;
    phone: string;
    celphone: string;
  };
}

export function useUser(variables: { id: string }) {
  return useQuery<GetUser>(GET_USER, {
    context: { uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL },
    fetchPolicy: 'cache-first',
    variables,
  });
}
