import { Button, DatePicker, Divider, Space, Typography } from 'antd';
import { set } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import ConsumptionTable from './ConsumptionTable';
import { useAuth } from '../../contexts/auth';
import User from '../../common/interfaces/User';
import {
  GetExtractEnterprisePlan,
  GetExtractEnterprisePlanVariables,
  GET_EXTRACT_ENTERPRISE_PLAN,
} from '../../common/graphql/GetExtractEnterprisePlan';

const { Title } = Typography;

export interface AddressFull {
  address?: string;
  number?: string;
  neighborhood?: string;
  complement?: string;
  city?: string;
  state?: string;
  zip_code?: string;
}

export interface OrderService {
  id: string;
  number_request_internal: string;
  date_solicitation: string;
  address_inspection: AddressFull;
  user: User;
  inspection: {
    inspector: {
      user: {
        name: string;
      }
    }
  };
}

export interface ReportLogData {
  id: string;
  balance_previous: number;
  consumption_metreage: number;
  addition_metreage: number;
  balance_current: number;
  activity: string;
  orderService: OrderService;
}

export default function Statement() {
  const { user } = useAuth();
  const today = new Date();
  const [date, setDate] = useState({
    start: set(today, { month: today.getMonth() - 1, hours: 0 }),
    end: today,
  });

  const [queryExtractEnterprisePlan, { data: dataExtract, loading }] =
    useLazyQuery<GetExtractEnterprisePlan, GetExtractEnterprisePlanVariables>(
      GET_EXTRACT_ENTERPRISE_PLAN,
      {
        context: {
          uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
        },
      }
    );

  const reportLog = dataExtract?.getExtractEnterprisePlan;
  const getFilteredReportLog = useCallback(
    () =>
      queryExtractEnterprisePlan({
        context: {
          uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
        },
        variables: {
          enterpriseId: user?.enterpriseId ?? '',
          dateBegin: date.start.toISOString(),
          dateEnd: date.end.toISOString(),
        },
      }),
    [date.end, date.start, queryExtractEnterprisePlan, user?.enterpriseId]
  );

  useEffect(() => {
    getFilteredReportLog();
  }, [getFilteredReportLog]);

  return (
    <section className="p-4">
      <Title level={2}>Extrato de consumo</Title>
      <Title level={4}>Período de consulta</Title>
      <div className="flex">
        <Space size="middle">
          <DatePicker.RangePicker
            format="DD/MM/yyyy"
            defaultValue={[moment(date.start), moment()]}
            disabledDate={current => current.valueOf() > Date.now()}
            onChange={moments => {
              if (!moments || !moments[0] || !moments[1]) return;
              setDate({ start: moments[0].toDate(), end: moments[1].toDate() });
            }}
          />

          <Button
            onClick={getFilteredReportLog}
            className="font-medium"
            type="primary"
          >
            Filtrar
          </Button>
        </Space>
      </div>
      <Divider />
      <ConsumptionTable dataSource={reportLog} loading={loading} />
    </section>
  );
}
