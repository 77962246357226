import { Button, Image, Skeleton, Typography } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { LeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React from 'react';

import OrderService from '../../common/interfaces/OrderService';
import { EnvironmentInspection } from '../../common/interfaces/Environment';
import { useEnvironmentMedia } from './useEnvironmentMedia';
import { MediaViewer } from './MediaViewer';

interface MediaFilesSectionProps {
  orderService: OrderService;
  className?: string;
}

const { Title, Text } = Typography;

export function MediaFiles({ orderService, ...props }: MediaFilesSectionProps) {
  const environments = orderService.inspection?.environments;
  const hasEnvironments = Boolean(environments?.length);

  const [searchParams] = useSearchParams();

  const selectedEnvironment = environments?.find(
    environment => environment.id === searchParams.get('environment')
  );

  return (
    <section {...props}>
      <Title level={4}>Fotos e Vídeos</Title>
      {selectedEnvironment ? (
        <EnvironmentMediaFiles environment={selectedEnvironment} />
      ) : hasEnvironments ? (
        <ul
          className="grid gap-4"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          }}
        >
          {orderService.inspection?.environments.map(environment => (
            <li
              key={environment.id}
              className="rounded border border-blue-400 bg-gray-50 hover:bg-gray-100 p-4"
            >
              <Link
                to={`?environment=${environment.id}`}
                replace
                className="block"
              >
                <Text className="text-lg font-medium">{environment.name}</Text>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <Text strong>Não há fotos nem vídeos</Text>
      )}
    </section>
  );
}

interface EnvironmentMediaFilesProps {
  environment: EnvironmentInspection;
}

function EnvironmentMediaFiles({ environment }: EnvironmentMediaFilesProps) {
  const { data, loading } = useEnvironmentMedia({
    environmentId: environment.id,
  });

  const photos = data?.environment.photos || [];
  const videos = data?.environment.videos || [];

  const [currentId, setCurrentId] = React.useState('');

  return (
    <div>
      <Title level={5} className="flex items-center justify-between">
        {environment.name}{' '}
        <Link to={window.location.pathname}>
          <Button type="link" className="flex items-center">
            <LeftOutlined /> Voltar
          </Button>
        </Link>
      </Title>

      <Skeleton loading={loading && !data}>
        <div
          className="grid gap-4"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          }}
        >
          {photos?.map(photo => (
            <Image
              className="cursor-pointer"
              key={photo.id}
              src={process.env.REACT_APP_DEPENDENCY_PHOTO + photo.path}
              onClick={() => setCurrentId(String(photo.id))}
              preview={false}
            />
          ))}

          {videos?.map(video => (
            <div
              className="relative cursor-pointer"
              onClick={() => setCurrentId(String(video.id))}
            >
              <video
                key={video.id}
                src={process.env.REACT_APP_DEPENDENCY_PHOTO + video.path}
                style={{ height: '100%', objectFit: 'cover' }}
              />
              <div className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
                <PlayCircleOutlined style={{ fontSize: 40, color: 'white' }} />
              </div>
            </div>
          ))}

          {currentId && (
            <MediaViewer
              currentId={currentId}
              onChange={setCurrentId}
              files={[
                ...photos.map(it => ({ ...it, type: 'photo' as const })),
                ...videos.map(it => ({ ...it, type: 'video' as const })),
              ]}
            />
          )}
        </div>
      </Skeleton>
    </div>
  );
}
