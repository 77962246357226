import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  gql,
  InMemoryCache,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';

const link = createHttpLink({
  uri: `${process.env.REACT_APP_FETCH_SERVER}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token =
    localStorage.getItem(process.env.REACT_APP_SESSION_KEY + '') + '';
  operation.setContext({
    headers: {
      'x-access-token': token,
      Authorization: token,
    },
  });

  return forward(operation);
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(error => {
  const errorHttp: any = error.networkError;
  if (errorHttp.statusCode === 403) {
    localStorage.clear();
    window.location.href = '/login';
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
  link: concat(authMiddleware, errorLink.concat(link)),
  typeDefs: gql`
    enum TypeInspectionEnum {
      STANDART
      PREMIUM
    }
  `,
  queryDeduplication: false,
});
export default client;
