import { ServiceOrderTypes, OSFormsValidation } from './types';
import { action } from 'typesafe-actions';
import OrderService from '../../../../common/interfaces/OrderService';

export const updateOS = (data: OrderService) =>
  action(ServiceOrderTypes.UPDATE_OS, data);
export const clearOS = () => action(ServiceOrderTypes.CLEAR_OS);
export const updateFormsValidation = (data: OSFormsValidation[]) =>
  action(ServiceOrderTypes.UPDATE_FORMS_VALIDATION, data);
export const clearValidarion = () => action(ServiceOrderTypes.CLEAR_VALIDATION);
