import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../contexts/auth';
import Plan from './Plan';
import { UserData } from './UserData';
import { useEnterpriseInfo } from './useEnterpriseInfo.hook';
import { useUser } from '../../hooks/useUser';

const { Title, Text } = Typography;

const styles = {
  box: 'border mb-4 p-2',
  section: 'mb-3',
  divider: 'my-2',
};

export default function Information() {
  const { user: localUser } = useAuth();

  if (!localUser) throw new Error('User does not exists!');

  const userQuery = useUser({ id: localUser.id });

  const {
    data: clientData,
    loading: clientLoading,
    refetch,
  } = useEnterpriseInfo({
    enterpriseId: parseInt(localUser.enterpriseId),
  });

  const location = useLocation();
  useEffect(() => {
    refetch();
  }, [location, refetch]);

  const enterprise = clientData?.enterpriseInfo;

  return (
    <section className="p-4">
      <Title level={2}>Dados pessoais</Title>
      <Skeleton loading={userQuery.loading && !userQuery.data}>
        {userQuery.data && (
          <UserData
            className={styles.box}
            divider={styles.divider}
            user={userQuery.data.user}
          />
        )}
      </Skeleton>
      <Skeleton loading={clientLoading}>
        <section className={styles.box}>
          <Title level={4}>Empresa</Title>
          <Divider className={styles.divider} />
          {enterprise && (
            <Row align="middle" gutter={[16, 16]}>
              {enterpriseInfo.map(({ title, prop }) => (
                <Col key={prop} span={12}>
                  <Title level={5}>{title}</Title>
                  <Text>
                    {prop === 'addressFull'
                      ? addressPieces
                          .filter(s => !!enterprise.addressFull[s])
                          .map((s, index) => (
                            <span key={s}>
                              {!!index && ', '}
                              {enterprise.addressFull[s]}
                            </span>
                          ))
                      : enterprise[prop]}
                  </Text>
                </Col>
              ))}
            </Row>
          )}
        </section>
      </Skeleton>
      <section className={styles.box}>
        <Plan className={styles.section} divider={styles.divider} />
      </section>
    </section>
  );
}

const enterpriseInfo: (Info & {
  prop: 'name' | 'phone' | 'addressFull' | 'email' | 'cnpj';
})[] = [
  { title: 'Nome', prop: 'name' },
  { title: 'CNPJ', prop: 'cnpj' },
  { title: 'Email de Contato', prop: 'email' },
  { title: 'Telefone', prop: 'phone' },
  { title: 'Endereço', prop: 'addressFull' },
];
const addressPieces = [
  'address',
  'number',
  'neighborhood',
  'city',
  'state',
] as const;

export interface Info {
  title: string | JSX.Element;
}
