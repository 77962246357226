import { Button, Checkbox, Typography } from 'antd';
import { InfoCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import {  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormsValidation } from '../../../common/redux/states/serviceOrder/actions';
import { AppState } from '../../../common/redux';
import { ServiceOrderTypes } from '../../../common/redux/states/serviceOrder/types';

interface Props {
  handleNext: () => void;
}

export default function Warning({ handleNext }: Props) {
  const { data } = useSelector((state: AppState) => state.OrderService);

  const [checked, setChecked] = useState(
    data && data.warningsChecked ? !!data.warningsChecked : false
  );

  const dispatch = useDispatch();

  const handleAcceptAndNext = () => {
    dispatch(updateFormsValidation([{ form: 'warnings', valid: true }]));
    handleNext();
  };

  return (
    <section className="p-4">
      <Typography.Title level={4} className="flex items-baseline gap-3">
        <InfoCircleOutlined /> Certifique-se de que:
      </Typography.Title>
      {phrases.map(phrase => (
        <Typography.Paragraph
          className="flex items-baseline gap-3"
          key={phrase}
        >
          <WarningTwoTone color="#304488" className="text-xl" />
          {phrase}
        </Typography.Paragraph>
      ))}
      <Checkbox
        onChange={e => {
          setChecked(e.target.checked);
          if (data?.warningsChecked === false) {
            dispatch(
              updateFormsValidation([{ form: 'warnings', valid: true }])
            );
          } else if (data?.warningsChecked === true) {
            dispatch(
              updateFormsValidation([{ form: 'warnings', valid: false }])
            );
          }
          dispatch({
            type: ServiceOrderTypes.UPDATE_OS,
            payload: { warningsChecked: e.target.checked },
          });
        }}
        defaultChecked={checked}
      >
        Estou ciente das informações acima listadas, sujeitas a cobrança em caso
        de vistorias não produtivas.
      </Checkbox>
      <Button
        onClick={handleAcceptAndNext}
        disabled={!checked}
        type="primary"
        className="block ml-auto"
      >
        Próximo
      </Button>
    </section>
  );
}

const phrases = [
  'Todas as chaves estejam disponíveis para retirada (incluindo controles, tags, etc)',
  'As manutenções no imóvel já tenham sido realizadas.',
  'O imóvel esteja completamente desocupado (sem móveis e pertences do ex-morador).',
  'A limpeza do imóvel já tenha sido realizada.',
];
