import { OrderService } from '../../../../views/OrderService/RequestSteps/Reviewer';

export enum ServiceOrderTypes {
  UPDATE_OS = '@serviceOrder/UPDATE_OS',
  CLEAR_OS = '@serviceOrder/CLEAR_OS',
  UPDATE_FORMS_VALIDATION = '@serviceOrder/UPDATE_FORMS_VALIDATION',
  CLEAR_VALIDATION = '@serviceOrder/CLEAR_VALIDATION'
}

export interface OSFormsValidation {
  form: string;
  valid: boolean;
}

export interface OrderServiceState {
  readonly data: OrderService | null;
  readonly formsValidation: OSFormsValidation[];
}
