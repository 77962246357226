import { gql } from '@apollo/client';
const GET_OS_FULL_CLIENT = gql`
  query getOSFull($id: String!) {
    getOrderServiceClient(id: $id) {
      status
      inspection {
        id
        dateStart
        dateFinish
        cancelReason
        inspector {
          user {
            name
            avatar
          }
        }
        environments {
          id
          name
          order
          dateStartInspection
          date_inspected
          date_described
        }
      }
      address_inspection {
        address
        number
        complement
        zip_code
        city
        neighborhood
        state
        latitude
        longitude
      }
      address_removal_key {
        address
        number
        complement
        zip_code
        city
        neighborhood
        state
      }
      address_delivery_key {
        address
        number
        complement
        zip_code
        city
        neighborhood
        state
      }
      orderServiceAppointment {
        dependencies_description
      }
      id
      description_client
      description_internal
      date_schedule_inspection
      type
      have_furniture
      number_request_internal
      date_solicitation
      date_canceled
      type_inspection
      finality_inspection
      finality
      hour_report_delivery
      metreage
      consumptionMetreage {
        metreage_base
        metreage_distance
        metreage_time_waiting
        metreage_immmobile_have_furniture
        metreage_immobile_type
        metreage_inspection_type
        metreage_delivery_report
        metreage_immobile_is_reform
        metreage_finality_inspection
      }
      planEnterpriseInvoice {
        metreage
        price
      }
      locators {
        id
        name
        last_name
        nationality
        status_civil
        cpf
        cnpj
      }
      tenants {
        id
        name
        last_name
        nationality
        status_civil
        cpf
        cnpj
      }
      guarantors {
        id
        name
        last_name
        nationality
        status_civil
        cpf
        cnpj
      }
      witness {
        id
        name
        last_name
        nationality
        status_civil
        cpf
        cnpj
      }
    }
  }
`;

export default GET_OS_FULL_CLIENT;
