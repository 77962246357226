import { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Typography,
  notification,
} from 'antd';

import Logo from '../assets/images/logo-bhouse.png';

import { useAuth } from '../contexts/auth';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { signIn } = useAuth();
  const setLocation = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [form] = Form.useForm();

  async function login(data: { username: string; password: string }) {
    setLoading(true);
    try {
      const error = await signIn(data);

      if (error) {
        notification.error({ message: error });
      } else {
        notification.success({ message: 'Logado com sucesso!', duration: 1.2 });
      }
      setLoading(false);

      setTimeout(() => {
        setLocation('/dashboard');
      }, 1000);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }

  return (
    <Row wrap={false} className="min-h-screen relative bg-white">
      <div className={`flex flex-col justify-center items-center min-h-full w-5/12 relative flex-shrink-0${process.env.REACT_APP_MODE == 'development' ? ' dev' : ''}`}>
        <div className="w-full max-w-md mx-auto p-5">
          <div className="pb-11">
            <a
              href="http://bhvistoriapredial.com.br"
              className="flex justify-center items-center"
            >
              <img
                className="max-h-32 max-w-full"
                src={Logo}
                alt="b house vistorias"
              />
            </a>
          </div>

          <div className="pb-5">
            <Typography.Title level={3}>Login</Typography.Title>
            <p className="font-medium">
              Acesse nosso painel com seu email e senha
            </p>
          </div>

          <Form
            noValidate
            form={form}
            onFinish={login}
            size="large"
            layout="vertical"
            validateTrigger="onBlur"
          >
            {error && !loading && (
              <Form.Item>
                <Alert
                  message={
                    <span className="d-flex justify-content-center font-weight-bolder">
                      Usuário ou senha incorretos
                    </span>
                  }
                  type="error"
                />
              </Form.Item>
            )}

            <Form.Item
              shouldUpdate
              validateTrigger={['onChange', 'onBlur']}
              label="Usuário"
              name="username"
              rules={[{ required: true, message: 'Informe seu usuário' }]}
              className="login-form-item"
            >
              <Input
                placeholder="Digite seu email"
                size="large"
                className="rounded"
              />
            </Form.Item>
            <Form.Item
              shouldUpdate
              label="Senha"
              name="password"
              validateTrigger="onBlur"
              rules={[{ required: true, message: 'Informe sua senha' }]}
              className="login-form-item"
            >
              <Input.Password
                placeholder="Digite sua senha"
                size="large"
                className="rounded"
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  disabled={
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                  block
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className="rounded"
                  size="large"
                >
                  Entrar
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="flex flex-shrink min-h-full w-full max-w-full bg-illustration-auth bg-no-repeat bg-center">
        <div className="w-full" />
      </div>
    </Row>
  );
}
