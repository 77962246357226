import { Typography } from 'antd';
import { useLoaderData } from 'react-router-dom';
import {
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryPie,
  VictoryStyleInterface,
} from 'victory';
import type { DashboardLoaderData } from '.';

import ImageSrc from '../../assets/images/custos.png';

export function Charts() {
  const {
    plan,
    getReportDivergenceAboutContestation: divergenceReport,
    getReportContestationGeneral,
  } = useLoaderData() as DashboardLoaderData;

  const STYLE: VictoryStyleInterface = {
    parent: { maxWidth: 400, maxHeight: 200, margin: '0 auto' },
  };

  const G1_DATA = [
    {
      x: 'Ordens de Serviço',
      y: getReportContestationGeneral.percentOrderService,
      color: '#0000FF',
    },
    {
      x: 'Contestações',
      y: getReportContestationGeneral.percentContestation,
      color: '#32CD32',
    },
  ];

  const G2_DATA = [
    {
      x: 'Ordem de Serviço',
      y: divergenceReport.percenteOrderService,
      color: '#0000FF',
    },
    {
      x: 'Contestações que procedem',
      y: divergenceReport.percenteDivergenceProceed,
      color: '#FFA500',
    },
  ];

  return (
    <div className="grid gap-8 bg-white rounded-lg px-7 py-6">
      <div className="flex justify-between">
        <div style={{ width: '45%', textAlign: 'center' }}>
          <Typography.Title level={4}>CONTESTAÇÃO GERAL</Typography.Title>
          <VictoryPie
            colorScale={G1_DATA.map(item => item.color)}
            data={G1_DATA}
            labelComponent={<CustomLabel />}
            innerRadius={80}
            style={STYLE}
          />

          <CustomLegend data={G1_DATA} />

          <p className="text-center">
            Este gráfico demonstra a eficiência das vistorias e laudos
            entregues, gerando redução de custo para sua imobiliária.
          </p>
        </div>

        <div style={{ width: '45%', textAlign: 'center' }}>
          <div className="flex flex-col items-center justify-center h-full">
            <div className="">
              <img src={ImageSrc} height={160} width={230} alt="" />
            </div>
            <div className="mt-5">
              <p style={{ fontSize: 20 }} className="font-medium text-center">
                + Redução de custo para sua imobiliária!
              </p>
            </div>
          </div>
        </div>

        {/* <div style={{ width: '45%', textAlign: 'center' }}>
          <Typography.Title level={4}>
            CONTESTAÇÃO REAL
          </Typography.Title>
          <VictoryPie
            colorScale={G2_DATA.map(item => item.color)}
            data={G2_DATA}
            labelComponent={<CustomLabel />}
            innerRadius={80}
            style={STYLE}
          />

          <CustomLegend data={G2_DATA} width={400} />

          <p className="text-center">
            Este gráfico demonstra se a contestação cadastrada procede ou não.
            Muitas vezes, a contestação já estava no laudo ou não procede.
          </p>
        </div> */}
      </div>

      {/* <div className="flex items-center justify-center gap-10">
        <img src={ImageSrc} height={160} width={200} alt="" />
        <p style={{ fontSize: 28 }} className="font-medium">
          + Redução de custo para sua imobiliária!
        </p>
      </div> */}
    </div>
  );
}

function CustomLabel(props: any) {
  const { datum, ...rest } = props as {
    x: number;
    y: number;
    datum: { color: string; y: number };
  };

  return (
    <g>
      <VictoryLabel
        {...rest}
        // textAnchor="end"
        // verticalAnchor="start"
        style={{
          fontSize: 26,
          fontWeight: 'bold',
          fill: datum.color,
        }}
        text={datum.y ? `${datum.y}%` : ''}
        // dy={-10}
        // dx={30}
      />
      <VictoryLine
        x={rest.x}
        y={rest.y}
        style={{ data: { stroke: datum.color, strokeWidth: 2 } }}
        data={[
          { x: 0, y: 0 },
          { x: 0, y: -50 },
        ]}
      />
    </g>
  );
}

function CustomLegend({ data, ...props }: { data: any[]; width?: number }) {
  return (
    <svg height={32} className="mx-auto" {...props}>
      <VictoryLegend
        orientation="horizontal"
        gutter={20}
        style={{
          labels: { fontFamily: "'Hind', sans-serif", fontWeight: 500 },
        }}
        data={data.map(item => ({
          name: item.x,
          symbol: { fill: item.color, type: 'circle' },
        }))}
        standalone={false}
      />
    </svg>
  );
}
