export function TooltipContent({
  justification,
  user,
}: {
  justification: string | undefined;
  user: string | undefined;
}) {
  return (
    <div>
      <h4 className="font-semibold">{user} - Motivo:</h4>
      <p className="text-black">
        {justification ?? 'A justificativa não foi registrada'}
      </p>
    </div>
  );
}
