import { Col, Divider, Row, Typography } from 'antd';
import { ReactElement } from 'react';
import OrderService from '../../common/interfaces/OrderService';
import { format } from 'date-fns';
import { FINALITY_INSPECTION } from '../../common/helpers/Finalities';

interface Props {
  orderService: OrderService;
  className: string | undefined;
  divider: string | undefined;
}

const { Title, Text } = Typography;

export default function InspectionData({
  orderService,
  className,
  divider,
}: Props) {

  // if (row.finality_inspection === 'location_entry') return 'Horário comercial'
  const displayDateInspection = orderService.finality_inspection === 'location_entry'
    ? 'Horário comercial'
    : orderService?.date_schedule_inspection
      ? format(new Date(orderService?.date_schedule_inspection), '  dd/MM/yyyy HH:mm')
      : '-'

  return (
    <section className={className}>
      <Title level={4}>Dados da vistoria</Title>
      <Divider className={divider} />
      <Row align="middle" gutter={[16, 16]} className="gap-3">
        {inpection.map(i => {
          let value;
          if (orderService) {
            value = orderService[i.value];
            const type = typeof value;
            if (type === 'boolean' || type === 'undefined')
              value = value ? 'Sim' : 'Não';
            if (value === 'standart') {
              value = 'Padrão';
            } else if (value === 'premium') {
              value = 'Prime';
            }
          }
          return (
            <Col sm={8} md={6} lg={5} key={i.key}>
              <Title level={5}>{i.key}</Title>
              <Text className={!i.unit ? 'capitalize' : ''}>
                {value}
                {i.unit && i.unit}
              </Text>
            </Col>
          );
        })}
        <Col sm={8} md={6} lg={5} key={1500}>
          <Title level={5}>Data do agendamento</Title>
          <Text className={'capitalize'}>
            {displayDateInspection}
          </Text>
        </Col>
        <Col sm={8} md={6} lg={5} key={1500}>
          <Title level={5}>Data da solicitação</Title>
          <Text className={'capitalize'}>
            {orderService?.date_solicitation ? format(new Date(orderService?.date_solicitation), '  dd/MM/yyyy HH:mm') : '-'}
          </Text>
        </Col>
        <Col sm={8} md={6} lg={5} key={1500}>
          <Title level={5}>Tipo de mobília</Title>
          <Text className={'capitalize'}>
            {orderService?.have_furniture && 'Mobiliado'}
            {orderService?.have_semi_furniture && 'Semi-mobiliado'}
            {!orderService?.have_furniture && !orderService?.have_semi_furniture && 'Sem mobília'}
          </Text>
        </Col>
        <Col sm={8} md={6} lg={5} key={1500}>
          <Title level={5}>Finalidade</Title>
          <Text className={'capitalize'}>
            {orderService?.finality_inspection ? FINALITY_INSPECTION[orderService.finality_inspection as keyof typeof FINALITY_INSPECTION] : '-'}
          </Text>
        </Col>
      </Row>
    </section>
  );
}

const inpection: {
  key: string;
  value: keyof OrderService;
  unit?: ReactElement | string;
}[] = [
  {
    key: 'Metragem',
    value: 'metreage',
    unit: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  { key: 'Tipo de imóvel', value: 'type' },
  { key: 'Ocupação', value: 'finality' },
  { key: 'Tipo de vistoria', value: 'type_inspection' }
];
