import { Button, Col, Divider, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  divider?: string;
  user: Pick<User, 'email' | 'name' | 'phone' | 'celphone'>;
}

const userInfo: {
  title: string;
  prop: 'name' | 'email' | 'phone' | 'celphone';
}[] = [
  { title: 'Nome', prop: 'name' },
  { title: 'Email', prop: 'email' },
  { title: 'Telefone fixo', prop: 'phone' },
  { title: 'Celular', prop: 'celphone' },
];

const { Title, Text } = Typography;

export function UserData({ className, divider, user }: Props) {
  return (
    <section className={className}>
      <Title level={4} className="flex justify-between">
        Meus dados
        <Link to="/user/edit">
          <Button type="primary" style={{ color: 'white' }}>
            Editar
          </Button>
        </Link>
      </Title>
      <Divider className={divider} />

      <Row align="middle" gutter={[16, 16]}>
        {userInfo.map(i => (
          <Col key={i.prop} span={12}>
            <Title level={5}>{i.title}</Title>
            <Text>{user[i.prop] ? user[i.prop] : 'Não informado'}</Text>
          </Col>
        ))}
      </Row>
    </section>
  );
}
