import OrderService from '../../common/interfaces/OrderService';
import { axiosInstanceSync } from '../axios';
import { isYesterday, isToday, isBefore, isAfter, isWeekend } from 'date-fns';

interface Variables {
  orderServiceId: string;
  dateReschedule: Date;
  dateRescheduleWithTime?: Date;
  dateToday?: Date;
}

export async function rescheduleOrderService(variables: Variables) {
  return await axiosInstanceSync.post(
    `/order-service/${variables.orderServiceId}/reschedule`,
    variables
  );
}

export function shouldAllowReschedule(data: OrderService) {
  if (!data.date_schedule_inspection) return true;

  const scheduleDate = new Date(data.date_schedule_inspection);
  const currentDate = new Date();

  const afterFifteenOfYesterday =
    (isYesterday(scheduleDate) && scheduleDate.getHours() > 15) ||
    isToday(scheduleDate);

  if (afterFifteenOfYesterday) return false;

  if (isBefore(scheduleDate, currentDate)) return true;

  return false;
}

export function isValidRescheduleDate(date: Date) {
  const isAfterDate = isAfter(date, Date.now());
  const isNotWeekend = !isWeekend(date);

  return isAfterDate && isNotWeekend;
}

export function removeOrderServiceAttachment(attachmentId: string) {
  return axiosInstanceSync.post(
    `/order-service/${attachmentId}/remove-attachment`
  );
}

interface UpdateVariables {
  id: string;
  description: string;
}

export function updateOrderServiceObservation(variables: UpdateVariables) {
  return axiosInstanceSync.put(`/client/order-service/${variables.id}/update`, {
    id: variables.id,
    data: {
      description_client: variables.description,
    },
  });
}
